export const debug = false

const errors = {
    /*
     * DEV Settings
     */
    "E0001": "Anmelden nicht möglich. Bitte vergewissere dich, dass du die richtige E-Mail-Adresse und Passwort verwendest.",
    "E0002": "Abrufen der User-Daten nicht möglich.",
    "E0003": "Fehler beim Erstellen einer Session.",
    "E0004": "Fehler beim Anlegen des Accounts in der Datenbank.",
    "E0005": "Fehler beim Erstellen der Standard-Einstellungen für den Account.",
    "E0006": "Fehler beim Erstellen des Accounts. Bitte prüfe, ob alle Pflichtfelder gefüllt sind.",
    "E0007": "Fehler beim Ausloggen.",

    /*
     EVENTS
    */
    "E0010": "Fehler beim Erstellen des Events.",
    "E0011": "Bitte lege einen Start und Endzeitpunkt fest.",
    "E0012": "Fehler beim Anzeigen der Hochzeiten als Event.",
    "E0013": "Fehler beim Anzeigen der Tasks als Event.",
    "E0014": "Fehler beim Anzeigen der Events.",
    "E0015": "Fehler beim  Anzeigen des Events.",
    "E0016": "Fehler beim  Löschen des Events.",

    /*
       CUSTOMER
     */
    "E0020": "Fehler beim Erstellen des Kunden.",
    "E0021": "Fehler beim  Löschen des Events.",
    "E0022": "Fehler beim Anzeigen der Kunden.",
    "E0023": "Fehler beim setzen der Kunden als Selektion.",
    "E0024": "Fehler beim Anzeigen des Kunden.",
    "E0025": "Fehler beim Bearbeiten des Kunden.",
    "E0026": "Bitte lege zunächst einen Start- und Endzeitpunkt fest.",

    /*
        FILE
     */
    "E0030": "Fehler beim Erstellen des Dokumenteneintrags.",
    "E0031": "Fehler beim  Upload des Dokuments.",
    "E0032": "Fehler beim Lesen des Dokuments.",
    "E0033": "Fehler beim Lesen der Dokumenteneinträge.",
    "E0034": "Fehler beim  Löschen des Dokumenteneintrags.",
    "E0035": "Fehler beim Löschen des Dokuments.",
    "E0036": "Fehler beim Setzen der Dokumenteneinträge beim während dem Löschen.",
    "E0037": "Fehler beim Laden des Dokuments.",

    /*
        SETTINGS
     */
    "E0040": "Fehler beim Laden der Kategorien.",
    "E0041": "Fehler beim  Laden der Dienstleister Status.",
    "E0042": "Fehler beim Laden der Hochzeit Status.",
    "E0043": "Fehler beim  Laden der Dienstleister Status für die Selektion.",
    "E0044": "Fehler beim Laden der Hochzeit Status für die Selektion.",
    "E0045": "Fehler beim Erstellen der Dienstleister-Kategorie.",
    "E0046": "Fehler beim Erstellen beim Erstellen des Status.",
    "E0047": "Fehler beim Löschen des Status.",
    "E0048": "Es gibt noch Dienstleister, die dieser Kategorie zugeordnet sind.",

    /*
    TASKS
    */
    "E0050": "Fehler beim Erstellen der Aufgabe.",
    "E0051": "Bitte lege zuerst einen Betreff fest.",
    "E0052": "Fehler beim Laden der Aufgaben.",
    "E0053": "Fehler beim  Laden der Aufgaben für die Hochzeit.",
    "E0054": "Fehler beim Laden des Kunden der Hochzeit der Aufgabe.",
    "E0055": "Fehler beim Laden der Hochzeit der Aufgabe.",
    "E0056": "Fehler beim Laden der Aufgabe.",
    "E0057": "Fehler beim Markieren der Aufgabe als erledigt.",
    "E0058": "Fehler beim Markieren der Aufgabe als nicht erledigt.",
    "E0059": "Fehler beim Löschen der Aufgabe.",

    /*
    VENDOR
    */
    "E0060": "Fehler beim Erstellen des Dienstleisters.",
    "E0061": "Fehler beim Erstellen des Haupt-Ansprechpartners.",
    "E0062": "Fehler beim Löschen des Ansprechpartners.",
    "E0063": "Fehler beim Bearbeiten des alten Haupt-Ansprechpartners.",
    "E0064": "Fehler beim Laden des alten Haupt-Ansprechpartners.",
    "E0065": "Fehler beim  Markieren des neuen Haupt-Ansprechpartners.",
    "E0066": "Fehler beim Löschen des Dienstleisters.",
    "E0067": "Fehler beim Laden der Dienstleister-Kategorie.",
    "E0068": "Fehler beim Laden der Dienstleister-Kategorie beim Laden der Dienstleister",
    "E0069": "Fehler beim Laden des Haupt-Ansprechpartners.",
    "E0070": "Fehler beim Laden der Dienstleister.",
    "E0071": "Fehler beim Laden der Dienstleister-Kategorie für die Selektion.",
    "E0072": "Fehler beim Laden der Dienstleister für die Selektion.",
    "E0073": "Fehler beim Laden der Dienstleister-Kategorie beim Laden des Dienstleisters",
    "E0074": "Fehler beim Laden des Dienstleisters.",
    "E0075": "Fehler beim Laden der Ansprechpartner des Dienstleisters.",

    /*
        WEDDINGS
    */
    "E0080": "Fehler beim Erstellen der Hochzeit.",
    "E0081": "Bitte lege zuerst einen Kunden für die Hochzeit fest.",
    "E0082": "Fehler beim Verlinken der Hochzeit mit dem Dienstleister.",
    "E0083": "Fehler beim Löschen der Hochzeit.",
    "E0084": "Fehler beim Laden des Kunden beim Laden der Hochzeiten.",
    "E0085": "Fehler beim Laden der Hochzeiten.",
    "E0086": "Fehler beim Laden der Dienstleister aus der Verbindung.",
    "E0087": "Fehler beim Laden der Dienstleister zu einer Hochzeit.",
    "E0088": "Fehler beim Laden des Kunden beim Laden der Hochzeit.",
    "E0089": "Fehler beim Laden der Hochzeit.",
    "E0090": "Fehler beim Löschen der Dienstleister-Verknüpfung zur Hochzeit.",
    "E0091": "Fehler beim Laden des Kunden beim Laden der Hochzeiten für die Selektion.",
    "E0092": "Fehler beim Laden der Hochzeiten für die Selektion.",

    /*
    GENERAL
     */
    "E0100": "Fehler beim Bearbeiten des Felds.",
    "E0101": "Fehler beim Ändern des Budgets.",
    "E0102": "Fehler beim bearbeiten des Datums.",

    /*
    BudgetPlan
    */
    'E0120': '',
    'E0121': '',
    /*
   Timeplan
   */
    'E0130': 'Die Endzeit darf nicht  vor der Startzeit liegen',
    'E0131': '',
}

export function getError(originalError, ErrorNo) {
    if (typeof ErrorNo === 'undefined') {
        return originalError
    }else{
        if (debug) {
            return "#" + ErrorNo +"["+errors[ErrorNo]+ "): " + originalError
        } else {
            return errors[ErrorNo] + " (#" + ErrorNo+")"
        }
    }
}

