import {databases} from "@/config/server-api";
import {Query} from "appwrite";
import {getError} from "@/config/errors";
import {
    eventCollection,
    taskCollection,
    weddingCollection,
    customerCollection,
} from "@/config/awSettings"
import {getMillsFromTime} from "@/helper/helper";

const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
    namespaced: true,
    state: {
        events: [],
        event: {},
        upcomingEvents: []
    },
    getters: {
        events: (state) => state.events,
        event: (state) => state.event,
        upcomingEvents: (state) => state.upcomingEvents,
    },
    actions: {
        /**
         *Erstellt ein Event
         *
         * @param payload
         */
        createEvent({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                if (payload.event.start && payload.event.end) {
                    const promiseCreateDoc = databases.createDocument(eventCollection, "unique()", {
                        name: payload.event.name,
                        start: payload.event.start,
                        end: payload.event.end,
                        color: payload.event.color,
                        details: payload.event.details,
                        timeEnd: payload.event.timeEnd,
                        timeStart: payload.event.timeStart,
                        plannerId: payload.uid
                    })

                    promiseCreateDoc.then(function () {
                        dispatch('getEvents', {uid: payload.uid})
                        resolve()
                    }, (error) => {
                        console.log(error)
                        reject(getError(error, "E0010"))
                    })
                } else {
                    reject(getError("Bitte lege einen Start und Endzeitpunkt fest.", "E0011"))
                }
            })
        },
        addEventsToEvents({commit}, payload) {
            return new Promise((resolve, reject) => {
                let events = []
                let upcomingEvents = []
                const promiseGetDocsEv = databases.listDocuments(eventCollection, [Query.equal("plannerId",
                    payload.uid
                )], 0)
                promiseGetDocsEv.then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(eventCollection, [Query.equal("plannerId",
                            payload.uid
                        )], 25, i * 25).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let timed = false
                                let start = doc.start
                                if (doc.timeStart) {
                                    start = start + getMillsFromTime(doc.timeStart)
                                    timed = true
                                }
                                let end = doc.end
                                if (doc.timeEnd) {
                                    end = end + getMillsFromTime(doc.timeEnd)
                                    timed = true
                                }

                                let event = {
                                    name: doc.name,
                                    start,
                                    end,
                                    timeStart: doc.timeStart,
                                    timeEnd: doc.timeEnd,
                                    color: 'primary',
                                    type: 'event',
                                    timed,
                                    details: doc.details,
                                    id: doc.$id,
                                }
                                events.push(event)
                                let now = new Date();
                                let eventDate = event.start
                                if (eventDate > now.getTime()) {
                                    upcomingEvents.push(event)
                                }
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('addEvents', events)
                                commit('addUpcomingEvents', upcomingEvents)
                                resolve()
                            }
                        })
                        await delay(100)
                    }
                }, (err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        addTasksToEvents({commit}, payload) {
            return new Promise((resolve, reject) => {
                let events = []
                let upcomingEvents = []
                const promiseGetDocsTa = databases.listDocuments(taskCollection, [Query.equal("plannerId",
                    payload.uid
                )], 0)
                promiseGetDocsTa.then(async function (documentList3X) {
                    if(documentList3X.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentList3X.total / 25)
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(taskCollection, [Query.equal("plannerId",
                            payload.uid
                        )], 25).then((documentList3) => {
                            for (let doc3 of documentList3.documents) {
                                let task = {
                                    name: 'Fällig: ' + doc3.subject,
                                    id: doc3.$id,
                                    start: doc3.date,
                                    end: doc3.date,
                                    color: 'secondary',
                                    timed: false,
                                    type: 'task',
                                    details: 'Die Aufgabe ' + doc3.subject + ' ist fällig.',
                                }
                                if (doc3.date !== null && doc3.status !== 'Erledigt') {
                                    events.push(task)
                                    let now = new Date();
                                    let eventDate = new Date(task.start)
                                    now.setHours(0, 0, 0, 0);
                                    if (eventDate < now) {
                                        task.color = '#BF2926'
                                    }
                                    upcomingEvents.push(task)
                                }
                            }
                        }).catch((err) => {
                            console.log(err); // Failure
                            reject(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('addEvents', events)
                                commit('addUpcomingEvents', upcomingEvents)
                                resolve()
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        addWeddingsToEvents({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocsWe = databases.listDocuments(weddingCollection, [Query.equal("plannerId",
                    payload.uid
                )], 0)
                promiseGetDocsWe.then(async function (documentList2X) {
                    if(documentList2X.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentList2X.total / 25)
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(weddingCollection, [Query.equal("plannerId",
                            payload.uid
                        )], 25, i*25).then((documentList2) => {
                            for (let doc2 of documentList2.documents) {
                                if (doc2.wedDate) {
                                    if (doc2.customerId) {
                                        const promiseGetDoc = databases.getDocument(customerCollection, doc2.customerId)
                                        promiseGetDoc.then(function (doc4) {
                                            let customer = {
                                                firstname: doc4.firstname,
                                                lastname: doc4.lastname,
                                                partnerFirstname: doc4.partnerFirstname,
                                                partnerLastname: doc4.partnerLastname,
                                                email: doc4.email,
                                                notes: doc4.notes,
                                                tel: doc4.tel,
                                                id: doc4.$id,
                                                created: doc4.$createdAt * 1000
                                            }
                                            let wedding = {
                                                name: 'Hochzeit von ' + customer.firstname + ' und ' + customer.partnerFirstname,
                                                id: doc2.$id,
                                                start: doc2.wedDate,
                                                end: doc2.wedDate,
                                                type: 'wedding',
                                                color: 'secondary',
                                                timed: false,
                                                details: 'Hochzeit von ' + customer.firstname + ' und ' + customer.partnerFirstname,
                                            }
                                            let now = new Date();
                                            let eventDate = new Date(wedding.start)
                                            now.setHours(0, 0, 0, 0);
                                            if (eventDate.setUTCHours(6, 0, 0, 0) > now) {
                                                commit('addUpcomingEvent', wedding)
                                            }
                                            commit('addEvent', wedding)
                                        }, (err) => {
                                            console.log(err)
                                            reject(err)
                                        })
                                    }
                                }
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                resolve()
                            }
                        })
                        await delay(100)
                    }
                }, (err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        /**
         * Liest alle Event, Tasks und Weddings mit einem Datum in Form eines Events
         *
         * @param dispatch
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getEvents({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('setEvents', [])
                commit('setUpcomingEvents', [])
                dispatch('addEventsToEvents', payload).then(() => {
                    dispatch('addTasksToEvents', payload).then(() => {
                        dispatch('addWeddingsToEvents', payload).then(() => {
                            resolve()
                        }).catch((err) => {
                            console.log(err)
                            reject(getError(err, "E0012"))
                        })
                    }).catch((err) => {
                        console.log(err)
                        reject(getError(err, "E0013"))
                    })
                }).catch((err) => {
                    console.log(err)
                    reject(getError(err, "E0014"))
                })
            })

        },
        /**
         * Liest Details zu einem Event-Datensatz
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getEvent({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(eventCollection, payload.eventId)
                promiseGetDoc.then(function (doc) {
                    let timeStart
                    let timeEnd
                    if (doc.timeStart) {
                        timeStart = doc.timeStart
                    } else {
                        timeStart = ''
                    }
                    if (doc.timeEnd) {
                        timeEnd = doc.timeEnd
                    } else {
                        timeEnd = ''
                    }
                    let event = {
                        name: doc.name,
                        start: new Date(doc.start),
                        end: new Date(doc.end),
                        timeStart,
                        timeEnd,
                        color: 'primary',
                        type: 'event',
                        timed: doc.timed,
                        details: doc.details,
                        id: doc.$id,
                    }
                    commit('setEvent', event)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0015"))
                });
            })
        },
        /**
         * Löscht ein Event
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        deleteEvent({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(eventCollection, payload.eventId);
                promiseDeleteDoc.then(function () {
                    dispatch('getEvents', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0016"))
                });
            })
        },
        /**
         * sort the objectArray from the payload
         *
         * @param dispatch
         * @param payload
         *  sortStyle: ASC oder DESC
         *  sortField: The fieldname that the values should sort to
         *  objectArray: List of Objects to sort
         */
        sort({dispatch}, payload) {

            let sortStyle = payload.sortStyle
            let sortField = payload.sortField
            let objectArray = payload.objectArray
            if (sortStyle === 'ASC') {
                dispatch('compare', {objectArray, sortField, dir1: -1, dir2: 1})
            } else {
                dispatch('compare', {objectArray, sortField, dir1: 1, dir2: -1})
            }

        },
        // eslint-disable-next-line no-empty-pattern
        compare({}, payload) {
            if (payload.objectArray[0]) {
                payload.objectArray.sort(function (a, b) {
                    if (a[payload.sortField] < b[payload.sortField]) {
                        return payload.dir1;
                    }
                    if (a[payload.sortField] > b[payload.sortField]) {
                        return payload.dir2;
                    }
                    return 0;
                })
            } else {
                payload.objectArray.sort(function (a, b) {
                    if (a[payload.sortField] < b[payload.sortField]) {
                        return payload.dir1;
                    }
                    if (a[payload.sortField] > b[payload.sortField]) {
                        return payload.dir2;
                    }
                    return 0;
                })
            }
        }
    },
    mutations: {
        setEvents(state, payload) {
            state.events = payload
        },
        setUpcomingEvents(state, payload) {
            state.upcomingEvents = payload
        },
        setEvent(state, payload) {
            state.event = payload
        },
        addUpcomingEvents(state, payload) {
            for (let i = 0; i < payload.length; i++) {
                state.upcomingEvents.push(payload[i])
            }
        },
        addEvents(state, payload) {
            for (let item of payload) {
                state.events.push(item)
            }
        },
        addUpcomingEvent(state, payload) {
            state.upcomingEvents.push(payload)
        },
        addEvent(state, payload) {
            state.events.push(payload)
        },
    }
}
