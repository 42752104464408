import {databases} from "@/config/server-api";
import {Query} from "appwrite";
import router from "@/router";
import {getError} from "@/config/errors";
import {
    customerCollection,
    invoiceCollection, invoicePositionCollection, invoiceSettingCollection
} from "@/config/awSettings"
import {date2String, dotToKomma} from "@/helper/helper";
const delay = ms => new Promise(res => setTimeout(res, ms));


export default {
    namespaced: true,
    state: {
        /**
         * Contains general invoices Information
         */
        invoices: [],
        quotes: [],
        /**
         * Contains general invoice Information
         */
        invoice: {},
        positions: [],
        /**
         * Kunden für die Selektion bei Dropdowns
         */
        invoicesArchived: [],
        quotesArchived: [],
        invoiceCreatedId: '',
        invoicesSearched: [],
        quotesSearched: [],
        invoicesSearchedArchived: [],
        quotesSearchedArchived: [],
        openSum: 0,
        openInvoice: 0,
        openSumQ: 0,
        openInvoiceQ: 0,
    },
    getters: {
        invoices: (state) => state.invoices,
        invoicesArchived: (state) => state.invoicesArchived,
        invoice: (state) => state.invoice,
        invoiceCreatedId: (state) => state.invoiceCreatedId,
        invoicesSearched: (state) => state.invoicesSearched,
        invoicesSearchedArchived: (state) => state.invoicesSearchedArchived,
        positions: (state) => state.positions,
        openSum: (state) => state.openSum,
        openInvoice: (state) => state.openInvoice,
        openSumQ: (state) => state.openSumQ,
        openInvoiceQ: (state) => state.openInvoiceQ,
        quotes: (state) => state.quotes,
        quotesArchived: (state) => state.quotesArchived,
        quotesSearched: (state) => state.quotesSearched,
        quotesSearchedArchived: (state) => state.quotesSearchedArchived,
    },
    actions: {
        createPosition({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let newDoc = {
                    plannerId: payload.uid,
                    invoiceId: payload.newInvoice.invoiceId,
                    isVorlage: false,
                    title: payload.newInvoice.title,
                    quant: payload.newInvoice.quant,
                    einheit: payload.newInvoice.einheit,
                    price: payload.newInvoice.price,
                    steuersatz: payload.newInvoice.steuersatz,
                    sum: payload.newInvoice.sum,
                    description: payload.newInvoice.description
                }
                const promiseCreateDoc = databases.createDocument(invoicePositionCollection, 'unique()', newDoc)
                promiseCreateDoc.then(function () {
                    dispatch('getPositionsForInvoice', {invoiceId: payload.newInvoice.invoiceId})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject('Fehler beim erstellen der Position.')
                })
            })
        },
        /**
         * Erstellt einen neuen Kunden
         *
         * @param dispatch
         * @param commit
         * @param payload
         */
        // eslint-disable-next-line no-empty-pattern
        createInvoice({commit}, payload) {
            console.log(payload)
            return new Promise((resolve, reject) => {
                if (payload.newInvoice.customerId !== '' && payload.newInvoice.customerId) {
                    const promiseGetDoc = databases.getDocument(customerCollection, payload.newInvoice.customerId)
                    promiseGetDoc.then(function (doc) {
                        let street = ''
                        if (doc.street) {
                            street = doc.street
                        }
                        let houseNumber = ''
                        if (doc.houseNumber) {
                            houseNumber = doc.houseNumber
                        }
                        let plz = ''
                        if (doc.plz) {
                            plz = doc.plz
                        }
                        let city = ''
                        if (doc.city) {
                            city = doc.city
                        }

                        let newDoc = {
                            customerId: payload.newInvoice.customerId,
                            invoiceDate: payload.newInvoice.invoiceDate,
                            invoiceNumber: payload.newInvoice.invoiceNumber,
                            subject: payload.newInvoice.subject,
                            date: payload.newInvoice.date,
                            status: payload.newInvoice.status,
                            plannerId: payload.uid,
                            header: payload.newInvoice.header,
                            footer: payload.newInvoice.footer,
                            street: street + ' ' + houseNumber,
                            city: plz + ' ' + city,
                            name: doc.firstname + ' ' + doc.lastname,
                            isQuote: payload.newInvoice.isQuote,
                            isArchived: false
                        }
                        let importId
                        if (payload.newInvoice.$id) {
                            importId = payload.newInvoice.$id
                        } else if (payload.newInvoice.id) {
                            importId = payload.newInvoice.id
                        } else {
                            importId = 'unique()'
                        }
                        delete payload.newInvoice['id'];
                        delete payload.newInvoice['$id'];
                        const promiseCreateDoc = databases.createDocument(invoiceCollection, importId, newDoc)
                        promiseCreateDoc.then(function (doc) {
                            commit('setInvoiceCreatedId', doc.$id)
                            router.push('/rechnungen/rechnung/' + doc.$id).then(() => {
                                resolve(doc.$id)
                            })
                        }, function (error) {
                            console.log(error)
                            reject('Fehler beim erstellen der Rechnung.')
                        })
                    }, function (error) {
                        console.log(error); // Failure
                        reject(getError(error, "E0024"))
                    });
                } else {
                    let newDoc = {
                        customerId: payload.newInvoice.customerId,
                        invoiceDate: payload.newInvoice.invoiceDate,
                        invoiceNumber: payload.newInvoice.invoiceNumber,
                        date: payload.newInvoice.date,
                        status: payload.newInvoice.status,
                        plannerId: payload.uid,
                        subject: payload.newInvoice.subject,
                        header: payload.newInvoice.header,
                        footer: payload.newInvoice.footer,
                        isQuote: payload.newInvoice.isQuote,
                        isArchived: false

                    }
                    let importId
                    if (payload.newInvoice.$id) {
                        importId = payload.newInvoice.$id
                    } else if (payload.newInvoice.id) {
                        importId = payload.newInvoice.id
                    } else {
                        importId = 'unique()'
                    }
                    delete payload.newInvoice['id'];
                    delete payload.newInvoice['$id'];
                    const promiseCreateDoc = databases.createDocument(invoiceCollection, importId, newDoc)
                    promiseCreateDoc.then(function (doc) {
                        commit('setInvoiceCreatedId', doc.$id)
                        if (payload.openAfterCreate) {
                            router.push('/rechnungen/rechnung/' + doc.$id).then(() => {
                                resolve(doc.$id)
                            })
                        } else {
                            resolve(doc.$id)
                        }
                    }, function (error) {
                        console.log(error)
                        reject('Fehler beim erstellen der Rechnung.')
                    })
                }
            })
        },
        /**
         * Löscht einen Kunden
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        deleteInvoice({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(invoiceCollection, payload.invoiceId);
                promiseDeleteDoc.then(function () {
                    dispatch('getInvoices', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0021"))
                });
            })
        },
        deletePosition({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(invoicePositionCollection, payload.positionId);
                promiseDeleteDoc.then(function () {
                    dispatch('getPositionsForInvoice', {invoiceId: payload.invoiceId})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0021"))
                });
            })
        },
        setInvoiceNumber({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let promise = databases.listDocuments(invoiceSettingCollection, [
                    Query.equal("plannerId", payload.uid)])
                promise.then((docs) => {
                    if (docs.documents.length < 1) {
                        reject()
                    } else {
                        let next
                        let prefix
                        let field
                        if (payload.isQuote) {
                            next = docs.documents[0].nextQuote
                            prefix = 'A-'
                            field = 'nextQuote'
                        } else {
                            next = docs.documents[0].nextInvoice
                            prefix = 'R-'
                            field = 'nextInvoice'
                        }
                        databases.updateDocument(invoiceSettingCollection, docs.documents[0].$id, {
                            [field]: next + 1
                        }).then(() => {
                            databases.updateDocument(invoiceCollection, payload.invoiceId, {
                                invoiceNumber: prefix + next
                            }).then(() => {
                                dispatch('getInvoice', {invoiceId: payload.invoiceId})
                                resolve()
                            }).catch((err) => {
                                reject(err)
                            })
                        }).catch((err) => {
                            reject(err)
                        })
                    }
                })
            })
        },
        getInvoicesForCustomer({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(invoiceCollection, [Query.equal("customerId",
                    payload.customerId
                )],100)
                promiseGetDocs.then(function (documentList) {
                    commit('setInvoices', documentList.documents)
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        /**
         * Holt und setzt alle Invoice eines bestimmten PLaners
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getPositionsForInvoice({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(invoicePositionCollection, [Query.equal("invoiceId",
                    payload.invoiceId
                )],100)
                promiseGetDocs.then(function (documentList) {
                    let positions = []
                    for (let doc of documentList.documents) {
                        let position = {
                            plannerId: doc.uid,
                            invoiceId: doc.invoiceId,
                            isVorlage: doc.isVorlage,
                            title: doc.title,
                            description: doc.description,
                            quant: doc.quant,
                            einheit: doc.einheit,
                            price: doc.price,
                            steuersatz: doc.steuersatz,
                            sum: doc.sum,
                            id: doc.$id,
                        }
                        positions.push(position)
                    }
                    commit('setPositions', positions)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject('Fehler beim Anzeigen der Positionen.')
                });
            })
        },

        getInvoices({commit}, payload) {
            return new Promise((resolve, reject) => {
                databases.listDocuments(invoiceCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("isArchived", false),
                    Query.equal("isQuote", false),
                ],0).then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let invoices = []
                    let sumOpen = 0
                    let countOpen = 0
                    for(let i = 0; i <pages; i++){
                        databases.listDocuments(invoiceCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("isArchived", false),
                            Query.equal("isQuote", false),
                        ],25,25*i) .then((documentList) => {
                            for (let doc of documentList.documents) {
                                let invoice = {
                                    values: {
                                        subject: doc.subject,
                                        invoiceNumber: doc.invoiceNumber,
                                        invoiceDate: date2String(new Date(doc.invoiceDate)),
                                        status: doc.status,
                                        price: dotToKomma(doc.price) + '€',
                                    },
                                    id: doc.$id,
                                }
                                if (doc.status === 1) {
                                    countOpen++
                                    sumOpen = sumOpen + doc.price
                                }
                                invoices.push(invoice)
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('setCountOpen', countOpen)
                                commit('setSumOpen', sumOpen)
                                commit('setInvoices', invoices)
                                commit('setSearchedInvoices', invoices)
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject('Fehler beim Anzeigen der Rechnungen.')
                });

                databases.listDocuments(invoiceCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("isArchived", true),
                    Query.equal("isQuote", false),
                ],0).then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let invoicesArchive = []
                    for(let i = 0; i <pages; i++){
                        databases.listDocuments(invoiceCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("isArchived", true),
                            Query.equal("isQuote", false),
                        ],25,25*i).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let invoice = {
                                    values: {
                                        subject: doc.subject,
                                        invoiceNumber: doc.invoiceNumber,
                                        invoiceDate: date2String(new Date(doc.invoiceDate)),
                                        status: doc.status,
                                        price: dotToKomma(doc.price) + '€',
                                    },
                                    id: doc.$id,
                                }
                                invoicesArchive.push(invoice)
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('setInvoicesArchive', invoicesArchive)
                                commit('setSearchedInvoicesArchive', invoicesArchive)
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject('Fehler beim Anzeigen der Rechnungen.')
                });

                databases.listDocuments(invoiceCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("isArchived", false),
                    Query.equal("isQuote", true),
                ],0).then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let quotes = []
                    let sumOpenQ = 0
                    let countOpenQ = 0
                    for(let i = 0; i <pages; i++){
                        databases.listDocuments(invoiceCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("isArchived", false),
                            Query.equal("isQuote", true),
                        ],25,25*i).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let invoice = {
                                    values: {
                                        subject: doc.subject,
                                        invoiceNumber: doc.invoiceNumber,
                                        invoiceDate: date2String(new Date(doc.invoiceDate)),
                                        status: doc.status,
                                        price: dotToKomma(doc.price) + '€',
                                    },
                                    id: doc.$id,
                                }
                                sumOpenQ = sumOpenQ + doc.price
                                countOpenQ++
                                quotes.push(invoice)
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('setCountOpenQ', countOpenQ)
                                commit('setSumOpenQ', sumOpenQ)
                                commit('setQuotes', quotes)
                                commit('setQuotesSearched', quotes)
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject('Fehler beim Anzeigen der Rechnungen.')
                });

                databases.listDocuments(invoiceCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("isArchived", true),
                    Query.equal("isQuote", true),
                ],0).then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let quotesArchive = []
                    for(let i = 0; i <pages; i++){
                        databases.listDocuments(invoiceCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("isArchived", true),
                            Query.equal("isQuote", true),
                        ],25,25*i).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let invoice = {
                                    values: {
                                        subject: doc.subject,
                                        invoiceNumber: doc.invoiceNumber,
                                        invoiceDate: date2String(new Date(doc.invoiceDate)),
                                        status: doc.status,
                                        price: dotToKomma(doc.price) + '€',
                                    },
                                    id: doc.$id,
                                }
                                quotesArchive.push(invoice)
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('setQuotesArchived', quotesArchive)
                                commit('setQuotesSearchedArchived', quotesArchive)
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject('Fehler beim Anzeigen der Rechnungen.')
                });
            })
        },
        /**
         * liest einen speziellen Invoice aus der Datenbank
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getInvoice({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(invoiceCollection, payload.invoiceId)
                promiseGetDoc.then(function (doc) {
                    if(doc.customerId && doc.customerId !== ''){
                        const promiseGetDoc = databases.getDocument(customerCollection, doc.customerId)
                        promiseGetDoc.then(function (doc2) {
                            let customer = {
                                firstname: doc2.firstname,
                                lastname: doc2.lastname,
                                partnerFirstname: doc2.partnerFirstname,
                                partnerLastname: doc2.partnerLastname,
                            }
                            let customerName = getCustomerName(customer.firstname, customer.partnerFirstname)
                            let invoice = {
                                subject: doc.subject,
                                id: doc.$id,
                                customerId: doc.customerId,
                                invoiceDate: new Date(doc.invoiceDate),
                                date: doc.date,
                                status: doc.status,
                                invoiceNumber: doc.invoiceNumber,
                                city: doc.city,
                                street: doc.street,
                                name: doc.name,
                                company: doc.company,
                                header: doc.header,
                                footer: doc.footer,
                                isArchived: doc.isArchived,
                                isQuote: doc.isQuote,
                                customerName: customerName
                            }
                            commit('setInvoice', invoice)
                            resolve()
                        }, function (error) {
                            console.log(error); // Failure
                            reject(getError(error, "E0024"))
                        });
                    }else{
                        let invoice = {
                            subject: doc.subject,
                            id: doc.$id,
                            customerId: doc.customerId,
                            invoiceDate: new Date(doc.invoiceDate),
                            date: doc.date,
                            status: doc.status,
                            invoiceNumber: doc.invoiceNumber,
                            city: doc.city,
                            street: doc.street,
                            name: doc.name,
                            company: doc.company,
                            header: doc.header,
                            footer: doc.footer,
                            isArchived: doc.isArchived,
                            isQuote: doc.isQuote,
                            customerName: ''
                        }
                        commit('setInvoice', invoice)
                        resolve()
                    }
                }).catch((err)=>{
                    reject(err)
                })
            })

        },
        setInvoicesSearched({commit}, payload) {
            return new Promise((resolve) => {
                commit('setSearchedInvoices', payload)
                resolve()
            })
        },
        setInvoicesSearchedArchived({commit}, payload) {
            return new Promise((resolve) => {
                commit('setSearchedInvoicesArchive', payload)
                resolve()
            })
        }

    },
    mutations: {
        setInvoices(state, payload) {
            state.invoices = payload
        },
        setInvoicesArchive(state, payload) {
            state.invoicesArchived = payload
        },
        setSearchedInvoices(state, payload) {
            state.invoicesSearched = payload
        },
        setSearchedInvoicesArchive(state, payload) {
            state.invoicesSearchedArchived = payload
        },
        addInvoice(state, payload) {
            state.invoices.push(payload)
        },
        setInvoice(state, payload) {
            state.invoice = payload
        },
        setPositions(state, payload) {
            state.positions = payload
        },
        setInvoiceCreatedId(state, payload) {
            state.invoiceCreatedId = payload
        },
        setSumOpen(state, payload) {
            state.openSum = payload
        },
        setCountOpen(state, payload) {
            state.openInvoice = payload
        },
        setSumOpenQ(state, payload) {
            state.openSumQ = payload
        },
        setCountOpenQ(state, payload) {
            state.openInvoiceQ = payload
        },
        setQuotes(state, payload) {
            state.quotes = payload
        },
        setQuotesArchived(state, payload) {
            state.quotesArchived = payload
        },
        setQuotesSearched(state, payload) {
            state.quotesSearched = payload
        },
        setQuotesSearchedArchived(state, payload) {
            state.quotesSearchedArchived = payload
        },
    }
}

function getCustomerName(firstname, partnerFirstname) {
    if (firstname !== '' && partnerFirstname === '') {
        return firstname
    } else if (firstname === '' && partnerFirstname !== '') {
        return partnerFirstname
    } else if (firstname !== '' && partnerFirstname !== '') {
        return firstname + " und " + partnerFirstname
    } else {
        return ''
    }
}
