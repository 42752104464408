import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#6D7E6D',
                secondary: '#B19983',
                tertiary: '#CECAB7',
                quaternary: '#EDE9D4',
                quinary: '#CFC6C1',
                senary: '#F1E7E2',
            }
        }
    }
});
