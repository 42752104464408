import {getError} from "@/config/errors";
import {
    budgetPlanCollection,
    contactPersonCollection,
    customerCollection,
    customFieldValueCollection,
    eventCollection, historyEntryCollection,
    leadCollection,
    locationCollection,
    notificationCollection,
    plannerAccountCollection,
    taskCollection,
    vendorCollection,
    weddingCollection,
    weddingVendorsCollection
} from "@/config/awSettings";
import {databases} from "@/config/server-api";



export default {
    namespaced: true,
    state: {
    }, getters: {

    }, actions: {
        // eslint-disable-next-line no-empty-pattern
        editField({}, payload) {
            let editValue = payload.newValue
            return new Promise((resolve, reject) => {
                console.log(payload)
                let entity;
                console.log(payload)
                if (payload.row === 'wedDate' || payload.row === 'date' || payload.row === 'start' || payload.row === 'end') {
                    if (!payload.newValue) {
                        reject(getError("Bitte gültigen Wert eingeben.", "E0100"))
                        return
                    } else {
                        editValue = new Date(editValue).getTime()
                    }
                }
                switch (payload.entity) {
                    case "vendors":
                        entity = vendorCollection
                        break;
                    case "contactPerson":
                        entity = contactPersonCollection
                        break;
                    case "wedding":
                        entity = weddingCollection
                        break;
                    case "events":
                        entity = eventCollection
                        break;
                    case "weddingVendors":
                        entity = weddingVendorsCollection
                        break;
                    case "lead":
                        entity = leadCollection
                        break;
                    case "tasks":
                        entity = taskCollection
                        break;
                    case "customers":
                        entity = customerCollection
                        break;
                    case "planner":
                        entity = plannerAccountCollection
                        break;
                    case "budgetPlan":
                        entity = budgetPlanCollection
                        break;
                    case 'customFieldValue':
                        entity = customFieldValueCollection
                        break;
                    case 'notifications':
                        entity = notificationCollection
                        break;
                    case 'locations':
                        entity = locationCollection
                        break;
                    case 'historyEntry':
                        entity = historyEntryCollection
                        break;
                }
                console.log(entity)
                const promiseUpdateDoc = databases.updateDocument(entity, payload.id, {
                    [payload.row]: editValue
                });
                promiseUpdateDoc.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0100"))
                });
            })
        }
    },
    mutations: {
    }
}
