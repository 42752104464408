import {databases, account, functions} from "../../config/server-api";
import {getError} from "@/config/errors";
import {
    leadCollection,
    bugReportCollection,
} from "@/config/awSettings"

export default {
    namespaced: true,
    state: {
        inbox: [],
        mailsLoaded: 0
    },
    getters: {
        inbox: (state) => state.inbox,
        mailsLoaded: (state) => state.mailsLoaded,

    },
    actions: {
        /**
         * Erstellt einen neuen Lead
         *
         * @param payload
         * @returns {Promise<unknown>}
         */
        // eslint-disable-next-line no-empty-pattern
        createMessage({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseSession = account.createAnonymousSession()
                promiseSession.then(function () {
                    const promiseCreateDoc = databases.createDocument(leadCollection, "unique()", payload)
                    promiseCreateDoc.then(function () {
                        console.log('successMessage')
                        resolve()
                    }, function (error) {
                        console.log(error)
                        reject(error)
                    })
                }, function (error) {
                    console.log(error)
                    reject(getError(error,"E0101"))
                })

            })
        },
        // eslint-disable-next-line no-empty-pattern
        createBugReport({}, payload) {
            return new Promise((resolve, reject) => {
                if( !payload.bugReport.bug){
                    payload.bugReport.bug = false
                }
                if(!payload.bugReport.optimizing){
                    payload.bugReport.optimizing = false
                }
                const promiseCreateDoc = databases.createDocument(bugReportCollection, "unique()", payload.bugReport)
                promiseCreateDoc.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(getError(error,"E0101"))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        getInbox({commit}){
            return new Promise((resolve, reject) => {
                functions.createExecution('641f30437a849aa2594a', '0').then((ex) => {
                    if(JSON.parse(ex.response).msgs){
                        let list = JSON.parse(ex.response).msgs
                        list.sort(function(a,b){
                            // Turn your strings into dates, and then subtract them
                            // to get a value that is either negative, positive, or zero.
                            return new Date(b.date) - new Date(a.date);
                        });
                        commit('setMailsLoaded', 25)
                        commit('setInbox', list)
                        resolve(list)
                    }else{
                        reject('Fehler beim Laden der E-Mails.')
                    }
                }).catch((err)=>{
                    console.log(err); // Failure
                    reject(err)
                })
            })
        },
        getNextInboxPack({commit},payload){
            return new Promise((resolve, reject) => {
                let offset = payload.mailsLoaded
                functions.createExecution('641f30437a849aa2594a',offset.toString()).then((ex) => {
                    if(JSON.parse(ex.response).msgs){
                        let list = JSON.parse(ex.response).msgs
                        commit('setMailsLoaded', offset+25)
                        commit('addInbox', list)
                        resolve(list)
                    }else{
                        reject('Fehler beim Laden der E-Mails.')
                    }
                }).catch((err)=>{
                    console.log(err); // Failure
                    reject(err)
                })
            })
        },
        getMailsForCustomer({commit},payload){
            return new Promise((resolve, reject) => {
                commit('setInbox', [])
                let email = payload.customer.email
                functions.createExecution('6425b7a9b0f099bbaf9c',email).then((ex) => {
                    if(JSON.parse(ex.response).msgs){
                        let list = JSON.parse(ex.response).msgs
                        commit('setInbox', list)
                        resolve(list)
                    }else{
                        reject('Fehler beim Laden der E-Mails.')
                    }
                }).catch((err)=>{
                    console.log(err); // Failure
                    reject(err)
                })
            })
        },
    },
    mutations: {
        setInbox(state, payload) {
            state.inbox = payload
        },
        addInbox(state, payload) {
            for(let mail of payload){
                state.inbox.push(mail)
            }
        },
        setMailsLoaded(state, payload) {
            state.mailsLoaded = payload
        },
    }
}
