import {databases, storage} from "@/config/server-api";
import {Query} from "appwrite";
import {getError} from "@/config/errors";
import {
    vendorCollection,
    contactPersonCollection,
    vendorCategoryCollection, stilCollection, vendorServicesCollection, weddingFilesCollection, weddingFileBucket
} from "@/config/awSettings"
import router from "@/router";
import {latLng} from "leaflet";
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
    namespaced: true, state: {
        /**
         * Contains general vendors Information
         */
        vendors: [],
        vendorsArchived: [],
        vendorsForSelection: [], /**
         * Contains general vendor Information
         */
        vendor: {},
        createdVendor: '',
        vendorsSearched: [],
        vendorsSearchedArchived: []
    }, getters: {
        vendors: (state) => state.vendors,
        vendorsArchived: (state) => state.vendorsArchived,
        vendorsForSelection: (state) => state.vendorsForSelection,
        vendor: (state) => state.vendor,
        createdVendor: (state) => state.createdVendor,
        vendorsSearched: (state) => state.vendorsSearched,
        vendorsSearchedArchived: (state) => state.vendorsSearchedArchived
    }, actions: {
        createVendor({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let name
                if (payload.newVendor.name === '') {
                    name = payload.newVendor.firstname + ' ' + payload.newVendor.lastname;
                } else {
                    name = payload.newVendor.name
                }
                let importId
                if(payload.newVendor.$id){
                    importId = payload.newVendor.$id
                }else if(payload.newVendor.id){
                    importId = payload.newVendor.id

                }
                else{
                    importId = 'unique()'
                }
                delete payload.newVendor['id'];
                delete payload.newVendor['$id'];
                const promiseCreateDoc = databases.createDocument(vendorCollection, importId, {
                    name,
                    category: payload.newVendor.category,
                    plannerId: payload.uid,
                    stil: payload.newVendor.stil,
                    priceClass: payload.newVendor.priceClass,
                    services: payload.newVendor.services,
                })
                promiseCreateDoc.then(function (doc) {
                    let newContactPerson = {
                        firstname: payload.newVendor.firstname,
                        lastname: payload.newVendor.lastname,
                        tel: payload.newVendor.tel,
                        isMain: true,
                        role: payload.newVendor.role,
                        plannerId: payload.uid,
                    }
                    if (payload.newVendor.email) {
                        newContactPerson = {
                            ...newContactPerson,
                            email: payload.newVendor.email
                        }
                    }
                    dispatch('createContactPerson', {
                        uid: payload.uid, vendorId: doc.$id, newContactPerson: newContactPerson
                    }).then(() => {
                        if (payload.openAfterCreate) {
                            router.push('/dienstleister/dienstleister/' + doc.$id).then(() => {
                                resolve(doc.$id)
                            })
                        } else {
                            resolve(doc.$id)
                        }
                    }).catch((err) => {
                        reject(getError(err, "E0060"))
                    })
                }, function (error) {
                    console.log(error)
                    reject(getError(error, "E0061"))
                }).catch((e) => {
                    console.log(e);
                    reject(e)
                });
            })
        }, // eslint-disable-next-line no-empty-pattern
        createContactPerson({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = databases.createDocument(contactPersonCollection, "unique()", {
                    firstname: payload.newContactPerson.firstname,
                    lastname: payload.newContactPerson.lastname,
                    email: payload.newContactPerson.email,
                    tel: payload.newContactPerson.tel,
                    isMain: payload.newContactPerson.isMain,
                    role: payload.newContactPerson.role,
                    vendorId: payload.vendorId,
                    plannerId: payload.uid
                })
                promiseCreateDoc.then(function () {
                    dispatch('getVendor', {vendorId: payload.vendorId})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(error)
                });
            })
        },
        deleteContactPerson({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(contactPersonCollection, payload.contactPerson.$id);
                promiseDeleteDoc.then(function () {
                    dispatch('getVendor', {vendorId: payload.vendorId})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0062"))
                });
            })
        },
        markAsMain({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseUpdateDoc = databases.updateDocument(contactPersonCollection, payload.contactPerson.$id, {
                    isMain: true
                });
                promiseUpdateDoc.then(function () {
                    const PromiseGetallContactPersons = databases.listDocuments(contactPersonCollection,
                        [Query.equal("vendorId", payload.vendorId),
                            Query.equal("isMain", true),
                            Query.notEqual("$id", payload.contactPerson.$id)
                        ],100)

                    PromiseGetallContactPersons.then((docs) => {
                        for (let doc of docs.documents) {

                            const PromiseUpdateContactPerson = databases.updateDocument(contactPersonCollection, doc.$id, {
                                isMain: false
                            });
                            PromiseUpdateContactPerson.then(() => {
                                dispatch('getVendor', {vendorId: payload.vendorId}).then(() => {
                                    resolve()
                                })
                            }, (error) => {
                                console.log(error); // Failure
                                reject(getError(error, "E0063"))
                            })
                        }
                    }, function (error) {
                        console.log(error); // Failure
                        reject(getError(error, "E0064"))
                    })
                    dispatch('getVendors', {uid: payload.uid})
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0065"))
                });
            })
        }, // eslint-disable-next-line no-empty-pattern
        deleteVendor({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                // ContactPersons löschen
                databases.listDocuments(contactPersonCollection, [Query.equal("vendorId", payload.vendorId), Query.equal("isMain", true)]).then((cpDocumentList) => {
                    for (let cpDoc of cpDocumentList.documents) {
                        databases.deleteDocument(contactPersonCollection, cpDoc.$id).catch((err)=>{
                            console.log(err)
                        })
                    }
                })

                // Files löschen
                databases.listDocuments(weddingFilesCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("vendorId", payload.vendorId)
                ],100).then((fileDocs) => {
                    for (let fileDoc of fileDocs.documents) {
                        storage.deleteFile(weddingFileBucket, fileDoc.docId).then(() => {
                            databases.deleteDocument(weddingFilesCollection, fileDoc.$id).catch((err) => {
                                console.log(err)
                            })
                        })
                    }
                })

                // vendor löschen
                const promiseDeleteDoc = databases.deleteDocument(vendorCollection, payload.vendorId);
                promiseDeleteDoc.then(function () {
                    dispatch('getVendors', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0066"))
                });
            })
        },
        // eslint-disable-next-line no-empty-pattern
        getCategory({}, payload) {
            return new Promise((resolve, reject) => {
                if (payload.categoryId) {
                    const promiseGetDocs = databases.getDocument(vendorCategoryCollection, payload.categoryId)
                    promiseGetDocs.then(function (doc) {
                        let category = {
                            name: doc.name,
                            icon: doc.icon,
                            value: doc.$id
                        }
                        resolve(category)
                    }, function (error) {
                        console.log(error); // Failure
                        reject(getError(error, "E0067"))
                    });
                } else {
                    resolve({})
                }
            })
        },
        // eslint-disable-next-line no-empty-pattern
        getStil({}, payload) {
            return new Promise((resolve, reject) => {
                let stilList = [];
                payload.stilIds?.forEach((stilId) => {
                    const promiseGetDocs = databases.getDocument(stilCollection, stilId)
                    promiseGetDocs.then(function (doc) {
                        let stil = {
                            name: doc.name,
                            value: doc.$id
                        }
                        stilList.push(stil)
                    }, function (error) {
                        console.log(error); // Failure
                        reject('Stilrichtungen konnten nicht geladen werden.')
                    });
                })
                resolve(stilList)
            })
        },
        // eslint-disable-next-line no-empty-pattern
        getVendorServices({}, payload) {
            return new Promise((resolve, reject) => {
                let serviceList = [];
                payload.serviceIds?.forEach((service) => {
                    const promiseGetDocs = databases.getDocument(vendorServicesCollection, service)
                    promiseGetDocs.then(function (doc) {
                        let service = {
                            name: doc.name,
                            value: doc.$id
                        }
                        serviceList.push(service)
                    }, function (error) {
                        console.log(error); // Failure
                        reject(getError(error, "E0067"))
                    });
                })
                resolve(serviceList)
            })
        },
        getVendors({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(vendorCollection, [Query.equal("plannerId", payload.uid)],0)
                promiseGetDocs.then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let vendors = []
                    let vendorsArchived = []
                    for(let i = 0; i <pages; i++){
                        databases.listDocuments(vendorCollection, [Query.equal("plannerId", payload.uid
                            )],25,25*i).then((documentList) => {
                            for (let doc of documentList.documents) {
                                const PromiseGetallContactPersons = databases.listDocuments(contactPersonCollection, [Query.equal("vendorId", doc.$id), Query.equal("isMain", true)],100)
                                PromiseGetallContactPersons.then((cpDocumentList) => {
                                    for (let cpDoc of cpDocumentList.documents) {
                                        if (doc.category) {
                                            dispatch('getCategory', {categoryId: doc.category}).then((category) => {
                                                dispatch('getStil', {stilIds: doc.stil}).then((stils) => {
                                                    dispatch('getVendorServices', {serviceIds: doc.services}).then((services) => {
                                                        let vendor = {
                                                            values: {
                                                                name: doc.name,
                                                                category,
                                                                priceClass: doc.priceClass,
                                                                stil: stils,
                                                                services: services
                                                            },
                                                            export: doc,
                                                            latLng: latLng(doc.lat, doc.lng),
                                                            id: doc.$id,
                                                            isArchived: doc.isArchived
                                                        }
                                                        delete vendor.export['$collection'];
                                                        delete vendor.export['$read'];
                                                        delete vendor.export['$write'];

                                                        if (doc.isArchived) {
                                                            vendorsArchived.push(vendor)
                                                        } else {
                                                            vendors.push(vendor)
                                                        }
                                                    }).catch((err) => {
                                                        console.log(err)
                                                        reject(err)
                                                    })
                                                }).catch((err) => {
                                                    console.log(err)
                                                    reject(err)
                                                })
                                            }).catch((err) => {
                                                console.log(err)
                                                reject(getError(err, "E0068"))
                                            })
                                        } else {
                                            let vendor = {
                                                values: {
                                                    name: doc.name,
                                                    category: doc.category,
                                                    stil: doc.stil,
                                                    priceClass: doc.priceClass,
                                                    email: cpDoc.email,
                                                },
                                                id: doc.$id,
                                            }
                                            if (doc.isArchived) {
                                                vendorsArchived.push(vendor)
                                            } else {
                                                vendors.push(vendor)
                                            }
                                        }
                                    }
                                }, (error) => {
                                    console.log(error);
                                    reject(getError(error, "E0069"))
                                })
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('setVendors', vendors)
                                commit('setSearchedVendors', vendors)
                                commit('setSearchedVendorsArchive', vendorsArchived)
                                commit('setVendorsArchived', vendorsArchived)
                                resolve()
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0070"))
                });
            })
        },
        getVendorsForSelection({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(vendorCollection, [Query.equal("plannerId", payload.uid),

                ],0)
                promiseGetDocs.then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let vendors = []
                    for(let i = 0; i <pages; i++){
                        databases.listDocuments(vendorCollection, [Query.equal("plannerId", payload.uid),

                        ],25,25*i).then((documentList) => {
                            for (let doc of documentList.documents) {
                                dispatch('getCategory', {categoryId: doc.category}).then((category) => {
                                    let vendor = {
                                        text: doc.name + ' | ' + category.name,
                                        value: doc.$id
                                    }
                                    if (!doc.isArchived) {
                                        vendors.push(vendor)
                                    }
                                }, (err) => {
                                    console.log(err)
                                    reject(getError(err, "E0071"))
                                })
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('setVendorsForSelection', vendors)
                                resolve()
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0072"))
                });
            })
        },

        // eslint-disable-next-line no-empty-pattern
        getVendorNameFromId({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.getDocument(vendorCollection, payload.vendorId)
                promiseGetDocs.then(function (doc) {
                    resolve(doc.name)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        editCP({}, payload) {
            return new Promise((resolve, reject) => {
                databases.updateDocument(contactPersonCollection, payload.cpId, {
                    firstname: payload.cpfName,
                    lastname: payload.cplName,
                    role: payload.cpRole,
                    tel: payload.cpTel,
                    email: payload.cpEmail,
                }).then((doc) => resolve(doc.name))
                    .catch((e) => reject(e))
            })
        },
        getVendor({commit, dispatch}, payload) {
            //TODO Funktion überarbeiten um extra Felder zu integrieren.
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.getDocument(vendorCollection, payload.vendorId)
                let categorySave;

                //let stils;
                //let services;
                promiseGetDocs.then(function (doc) {
                    const PromiseGetallContactPersons = databases.listDocuments(contactPersonCollection, [Query.equal("vendorId", payload.vendorId)],100)
                    let contactPersons = [];
                    PromiseGetallContactPersons.then((cpDocumentList) => {
                        for (let cpDoc of cpDocumentList.documents) {
                            contactPersons.push(cpDoc)
                        }
                        let properDate = new Date(doc.$createdAt * 1000);
                        //if (doc.category) {
                        dispatch('getCategory', {categoryId: doc.category}).then((category) => {
                            categorySave = category
                            dispatch('getStil', {stilIds: doc.stil}).then((stils) => {
                                dispatch('getVendorServices', {serviceIds: doc.services}).then((services) => {
                                    let vendor = {
                                        name: doc.name,
                                        category: categorySave,
                                        notes: doc.notes,
                                        id: doc.$id,
                                        contactPersons,
                                        stil: stils,
                                        priceClass: doc.priceClass,
                                        services: services,
                                        street: doc.street,
                                        streetNumber: doc.streetNumber,
                                        plz: doc.plz,
                                        city: doc.city,
                                        created: properDate,
                                        lat: doc.lat,
                                        lng: doc.lng,
                                        latLng: latLng(doc.lat, doc.lng),
                                        isArchived: doc.isArchived
                                    }
                                    commit('setVendor', vendor)
                                    resolve(vendor)
                                }).catch((err) => {
                                    console.log(err)
                                    reject(getError(err, "E0073"))
                                })
                            }).catch((err) => {
                                console.log(err)
                                reject(getError(err, "E0073"))
                            })
                        }).catch((err) => {
                            console.log(err)
                            reject(getError(err, "E0073"))
                        })
                        /* } else {
                             let vendor = {
                                 name: doc.name,
                                 category: {},
                                 stil: doc.stil,
                                 priceClass: doc.priceClass,
                                 services: doc.services,
                                 notes: doc.notes,
                                 id: doc.$id,
                                 contactPersons,
                                 street: doc.street,
                                 streetNumber: doc.streetNumber,
                                 plz: doc.plz,
                                 city: doc.city,
                                 created: properDate
                             }
                             commit('setVendor', vendor)
                             resolve(vendor)
                         }
                         */
                    }, function (error) {
                        console.log(error); // Failure
                        reject(getError(error, "E0075"))
                    })
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0074"))
                });
            })
        },
        setVendorsSearched({commit}, payload) {
            return new Promise((resolve) => {
                commit('setSearchedVendors', payload)
                resolve()
            })
        },
        setVendorsSearchedArchived({commit}, payload) {
            return new Promise((resolve) => {
                commit('setSearchedVendorsArchive', payload)
                resolve()
            })
        }
    }, mutations: {
        setVendors(state, payload) {
            state.vendors = payload
        },
        setVendorsArchived(state, payload) {
            state.vendorsArchived = payload
        },
        setSearchedVendors(state, payload) {
            state.vendorsSearched = payload
        },
        setSearchedVendorsArchive(state, payload) {
            state.vendorsSearchedArchived = payload
        },
        setVendor(state, payload) {
            state.vendor = payload
        },
        setcreatedVendor(state, payload) {
            state.createdVendor = payload
        },
        setVendorsForSelection(state, payload) {
            state.vendorsForSelection = payload
        },
    }
}
