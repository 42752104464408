export const registerToken = 'TESTWedFlow!2022'


export const baseUrl   = 'https://dev.wedding-flow.de'
export const urlEssential = ''


/**
 * DEV Settings
 */
export const endpoint   = 'https://server.wedding-flow.de/v1'
export const projectId  = '63640f934449ae3a9a69' // DEV
export const databaseId = '62fd0b1b0855390a14c7'

/**
 * Collections
 */
export const plannerAccountCollection   = '62fd0b3731b3345a3b7d'

export const customerCollection         = '62fd2e1ec0ae6102fa37'
export const eventCollection            = '62ffb0d770e8a981ac5d'
export const taskCollection             = '62ffb0e04d4834ff7f59'
export const weddingCollection          = '62ffac2e8e224e2de4be'
export const weddingVendorsCollection   = '62ffc5fe40399d5e2723'
export const vendorCollection           = '62fd371648df0c365037'
export const contactPersonCollection    = '62fd3ff4c6b121caf8f3'
export const paymenStatusCollection     = '6354404f8d9857c8be81'

export const budgetPlanCollection       = '632385bea54ec38ba87d'
export const singleBudgetCollection     = '6323880674d6d3d9f075'
export const budgetPlanCategoryCollection   = '63238ba3aeb419622e23'
export const budgetPlanStandardCategoryCollection = '6324442190f0be2aa626'

export const timePlanCollection         = '634da4a6c77fbf41b12d'
export const timePlanEntryCollection    = '634da5230124359907f3'
export const timePlanEntryVendorCollection = '634da5e64efce6275ba3'

export const vendorCategoryCollection   = '630254be52d0879548e8'
export const vendorStatusCollection     = '6302550b0c7a6b9a77ad'
export const weddingStatusCollection    = '6302551ae48b8e55c2e4'
export const customerStatusCollection    = '639dc45c5f5b58a1a3c3'

export const customFieldCollection      = '63413595ac06cf4b61de'
export const customFieldValueCollection = '63419351750d0a218506'

export const notificationCollection     = '634bcee0b5e98aa2a265'
export const leadCollection             = '62ffe92923637740b3d8'
export const bugReportCollection        = '631f292e4cae8d4b0ab6'
export const historyEntryCollection     = '639a0567d94bbc476aec'
export const myProductsCollection       = '639ef1b58e3ca50f9463'
export const locationCollection         = '63a191125e3b35fc2370'
export const stilCollection             = '63a1d0901b848d9ba004'
export const vendorServicesCollection   = '63a1d0e6ed00ef73c971'
export const templateCollection         = '63adbd0ba9fbb0ad513f'
export const templateBudgetPlanCategoryCollection   = '63adbdf5c7b1c32d83c3'
export const templateBudgetPlanEntryCollection   = '63adbd1e04019e2321b5'
export const templateTimePlanEntriesCollection  = '63b34c507cd0e6da75cb'
export const weddingLabelCollection  = '63f3a0680fcb4b595cf8'
export const invoiceSettingCollection  = '63fcd1e880d5238083cb'
export const invoiceCollection  = '63fe3f7d9c9d3c625d22'
export const invoicePositionCollection  = '63ff6ef735e16def83ec'


/**
 * Buckets
 */
export const profilePicBucket           = '6408d9489176ad4a2688'
export const weddingFileBucket          = '63028c59e998a2a1a029'
export const weddingFilesCollection     = '63028d639c1c3c0c264c'
