export function error(msg) {
    setTimeout(() => this.message.show = false, 5000);
    return {
        show: true,
        text: msg,
        color: "error",
    }
}

export function checkIfRouteShouldGoToLogin(route) {
    console.log(route)
    if(
        route.includes('registrieren') ||
        route.includes('login') ||
        route.includes('impressum') ||
        route.includes('avv') ||
        route.includes('agb') ||
        route.includes('datenschutzerklaerung') ||
        route.includes('forgotPassword') ||
        route.includes('resetPasswort') ||
        route.includes('createAccount') ||
        route.includes('fast-geschafft') ||
        route.includes('activateMyAccount!wedFlow2022!')
    ){
        return false
    }else{
        return true
    }
}

export function success(msg) {
    setTimeout(() => this.message.show = false, 5000);
    return {
        show: true,
        text: msg,
        color: "success",
    }
}

export function msgObj() {
    return {
        show: false,
        text: "",
        color: ""
    }
}

export function date2String(date) {
    if (date !== null) {
        const newDate = new Date(date)
        if (newDate.getTime() === 0) {
            return ''
        }
        let options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return newDate.toLocaleDateString('de-DE', options)
    } else {
        return ''
    }
}
export function date2StringTime(date) {
    if (date !== null) {
        const newDate = new Date(date)
        if (newDate.getTime() === 0) {
            return ''
        }
        let options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}
        return newDate.toLocaleDateString('de-DE', options)
    } else {
        return ''
    }
}
export function dotToKomma(float) {
    if (float) {
        let string = (Math.round(float * 100) / 100).toString()
        let vorKomma = string.split('.')[0]
        let nachKomma = string.split('.')[1]

        if (nachKomma && nachKomma.length > 0) {
            if (nachKomma.length === 1) {
                return trenner(vorKomma) + ',' + nachKomma + '0'
            } else {
                return trenner(vorKomma) + ',' + nachKomma
            }
        } else {
            return trenner(vorKomma) + ',' + '00'
        }
    } else {
        return '0,00'
    }
}

export function getTimeFromMillis(time) {
    return new Date(time).toISOString().substr(0, 10);
}

export function getMillsFromTime(time) {
    const hour = Number(time.split(':')[0])
    const minute = Number(time.split(':')[1])

    let hourM = hour * 60 * 60 * 1000
    const minuteM = minute * 60000

    hourM = hourM - (2 * 60 * 60 * 1000)

    // Eine Stunde dazu, damit Anzeige im Kalender passt
    hourM = hourM + (60 * 60 * 1000)

    return hourM + minuteM
}
export function getDateForPicker(date) {
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();
    if (month.length < 2)
        month = '0' + month
    if (day.length < 2)
        day = '0' + day
    return [year, month, day].join('-')
}
export function trenner(number) {
    number = '' + number;
    if (number.length > 3) {
        let mod = number.length % 3;
        let output = (mod > 0 ? (number.substring(0, mod)) : '');
        for (let i = 0; i < Math.floor(number.length / 3); i++) {
            if ((mod === 0) && (i === 0))
                output += number.substring(mod + 3 * i, mod + 3 * i + 3);
            else
                // hier wird das Trennzeichen festgelegt mit '.'
                output += '.' + number.substring(mod + 3 * i, mod + 3 * i + 3);
        }
        return (output);
    } else return number;
}

export function getAllFieldsForEntity(entity){
    switch (entity) {
        case 'location':
            return [
                {text: 'Name', value: 'name'},
                {text: 'max. Personenanzahl', value: 'maxPersonen'},
                {text: 'Fläche innen', value: 'groesseInnen'},
                {text: 'Fläche außen', value: 'groesseAussen'},
                {text: 'Telefon', value: 'phone'},
                {text: 'E-Mail', value: 'email'},
                {text: 'Preiskategorie', value: 'priceClass'},
                {text: 'ID', value: 'id'},
                {text: 'Straße', value: 'street'},
                {text: 'Hausnummer', value: 'houseNumber'},
                {text: 'PLZ', value: 'plz'},
                {text: 'Ort', value: 'city'},
                {text: 'Latitude', value: 'lat'},
                {text: 'Longitude', value: 'lng'},
                {text: 'Archiviert', value: 'isArchived'},
            ]
        case 'vendor':
            return [
                {text: 'Name', value: 'name'},
                {text: 'Preiskategorie', value: 'priceClass'},
                {text: 'ID', value: 'id'},
                {text: 'Straße', value: 'street'},
                {text: 'Hausnummer', value: 'houseNumber'},
                {text: 'PLZ', value: 'plz'},
                {text: 'Ort', value: 'city'},
                {text: 'Latitude', value: 'lat'},
                {text: 'Longitude', value: 'lng'},
                {text: 'Archiviert', value: 'isArchived'},
                {text: 'Vorname (Kontakt)', value: 'firstname'},
                {text: 'Nachname (Kontakt)', value: 'lastname'},
                {text: 'Telefon (Kontakt)', value: 'tel'},
                {text: 'E-Mail (Kontakt)', value: 'email'},
                {text: 'Rolle (Kontakt)', value: 'role'},
            ]
        case 'customer':
            return [
                {text: 'Vorname', value: 'firstname'},
                {text: 'Nachname', value: 'lastname'},
                {text: 'Vorname (Partner)', value: 'partnerFirstname'},
                {text: 'Nachname (Partner)', value: 'partnerLastname'},
                {text: 'Telefon', value: 'tel'},
                {text: 'E-Mail', value: 'email'},
                {text: 'Telefon (Partner)', value: 'partnerTel'},
                {text: 'E-Mail (Partner)', value: 'partnerMail'},
                {text: 'Straße', value: 'street'},
                {text: 'Hausnummer', value: 'houseNumber'},
                {text: 'PLZ', value: 'plz'},
                {text: 'Ort', value: 'city'},
                {text: 'Archiviert', value: 'isArchived'},
            ]
        case 'wedding':
            return [
                {text: 'Hochzeitsdatum', value: 'wedDate'},
                {text: 'ID', value: 'id'},
                {text: 'Archiviert', value: 'isArchived'},
            ]
        default:
            return null
    }
}
