import {databases} from "@/config/server-api";
import {Query} from "appwrite";
import {getError} from "@/config/errors";
import {
    budgetPlanCollection,
    singleBudgetCollection,
    budgetPlanCategoryCollection,
    templateCollection,
    templateBudgetPlanCategoryCollection, templateBudgetPlanEntryCollection
} from "@/config/awSettings"


export default {
    namespaced: true,
    state: {
        /**
         * contains the complete budget plan
         */
        budgetPlan: {},
        budgetPlans: [],
        /**
         * contains the planned budget
         */
        budget: {},
        /**
         * contains the budget of all categories
         */
        categoriesBudget: [],
        budgetPlanTemplates: [],
        isInCreation: false,


    },
    getters: {
        /**
         * Get the current budget plan from the state
         *
         * @param state
         * @returns {[]}
         */
        budgetPlan: (state) => state.budgetPlan,
        budgetPlans: (state) => state.budgetPlans,
        /**
         * Get the budget from the state
         *
         * @param state
         * @returns {{}}
         */
        budget: (state) => state.budget,

        /**
         * Get the categories budget from state
         *
         * @param state
         * @returns {[]}
         */
        categoriesBudget: (state) => state.categoriesBudget,
        budgetPlanTemplates: (state) => state.budgetPlanTemplates,
        isInCreation:  (state) => state.isInCreation,

    },

    actions: {

        getBudget({commit}, payload) {
            databases.getDocument(budgetPlanCollection, payload).then((doc) => {
                commit("setBudget", doc.planned)
            })
        },
        // eslint-disable-next-line no-empty-pattern
        createBudgetCategory({}, payload) {
            return new Promise((resolve, reject) => {
                let newBudgetCategory = {
                    name: payload.name,
                    plannerId: payload.plannerId,
                    budgetPlanId: payload.budgetPlanId,
                    icon: payload.icon
                }
                databases.createDocument(budgetPlanCategoryCollection, "unique()", newBudgetCategory).then((doc) => {
                    resolve(doc)
                }).catch((e) => {
                    console.log(e);
                    reject(reject(getError("Fehler beim Anlegen der neuen Kategorien zum Budgetplan.", ""), e))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        createSingleBudget({}, payload) {
            return new Promise((resolve, reject) => {
                let newSingleBudget = {
                    name: payload.name,
                    plannerId: payload.plannerId,
                    budgetPlanId: payload.budgetPlanId,
                    planned: payload.planned,
                    spent: payload.spent,
                    categoryId: payload.categoryId
                }
                const promiseCreateDoc = databases.createDocument(singleBudgetCollection, "unique()", newSingleBudget)
                promiseCreateDoc.then((doc) => {
                    resolve(doc)
                }).catch((e) => {
                    console.log(e);
                    reject(reject(getError("Fehler beim Anlegen der Kategorien zum neuen Budgetplan.", ""), e))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteSingleBudget({}, payload) {
            return new Promise((resolve, reject) => {
                databases.deleteDocument(singleBudgetCollection, payload).then((doc) => {
                    resolve(doc)
                }).catch((e) => {
                    console.log(e);
                    reject(reject(getError("Fehler beim Löschen der Unterkategorien des Budgetplans", ""), e))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        editSingleBudget({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseUpdateDoc = databases.updateDocument(singleBudgetCollection, payload.subBudgetId, {
                    name: payload.subBudgetName,
                    spent: payload.subBudgetSpent,
                    planned: payload.subBudgetPlanned,
                });
                promiseUpdateDoc.then((doc) => {
                    resolve(doc)
                }).catch((e) => {
                    console.log(e);
                    reject(reject(getError("Fehler beim Anlegen der Kategorien zum neuen Budgetplan.", ""), e))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteBudgetPlan({}, payload) {
            return new Promise((resolve, reject) => {
                databases.listDocuments(singleBudgetCollection, [Query.equal("budgetPlanId",
                    payload.budgetPlanId
                )]).then((docs) => {
                        for (let singleBud of docs.documents) {
                            databases.deleteDocument(singleBudgetCollection, singleBud.$id).then(() => {
                            }).catch((err) => {
                                console.log(err)
                            })
                        }
                        databases.listDocuments(budgetPlanCategoryCollection, [Query.equal("budgetPlanId",
                            payload.budgetPlanId
                        )]).then((cats) => {
                            for (let cat of cats.documents) {
                                databases.deleteDocument(budgetPlanCategoryCollection, cat.$id).catch((err) => {
                                    console.log(err)
                                })
                            }
                            databases.deleteDocument(budgetPlanCollection, payload.budgetPlanId).then(() => {
                                resolve()
                            }).catch((err) => {
                                console.log(err)
                                reject(err)
                            })
                        })
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteTemplate({}, payload) {
            return new Promise((resolve, reject) => {
                databases.listDocuments(templateBudgetPlanCategoryCollection, [
                    Query.equal("budgetPlanId", payload.templateId)
                ]).then((docs) => {
                    for(let doc of docs.documents){
                        databases.listDocuments(templateBudgetPlanEntryCollection, [
                            Query.equal("categoryId", doc.$id),
                        ]).then((docs2) => {
                            for(let doc2 of docs2.documents){
                                databases.deleteDocument(templateBudgetPlanEntryCollection, doc2.$id).catch((err)=>{
                                    console.log(err)
                                    reject(err)
                                })
                            }
                            databases.deleteDocument(templateBudgetPlanCategoryCollection, doc.$id).catch((err)=>{
                                console.log(err)
                                reject(err)
                            })
                        }).catch((err) => {
                            console.log(err)
                            reject(err)
                        })
                    }
                    databases.deleteDocument(templateCollection, payload.templateId).catch((err)=>{
                        console.log(err)
                        reject(err)
                    })
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        saveAsTemplate({}, payload) {
            return new Promise((resolve, reject) => {
                databases.createDocument(templateCollection, 'unique()', {
                    type: 'budgetPlan',
                    plannerId: payload.uid,
                    name: payload.name,
                    description: payload.description,
                }).then((doc) => {
                    let templatePlanId = doc.$id
                    for (let category of payload.budgetPlan.budgetList) {
                        databases.createDocument(templateBudgetPlanCategoryCollection, 'unique()', {
                            plannerId: payload.uid,
                            name: category.name,
                            icon: category.icon,
                            budgetPlanId: templatePlanId
                        }).then((doc2) => {
                            let templateCategoryId = doc2.$id
                            for (let singleBudget of category.singleBudget) {
                                databases.createDocument(templateBudgetPlanEntryCollection, 'unique()', {
                                    plannerId: payload.uid,
                                    categoryId: templateCategoryId,
                                    budgetPlanId: templatePlanId,
                                    planned: singleBudget.planned,
                                    spent: singleBudget.spent,
                                    name: category.name,
                                }).catch((err) => {
                                    console.log(err)
                                    reject(err)
                                })
                            }
                        }).catch((err) => {
                            console.log(err)
                            reject(err)
                        })
                    }
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        getBudgetPlanTemplates({commit}, payload) {
            return new Promise((resolve, reject) => {
                let budgetPlans = []
                const promiseGetDocs = databases.listDocuments(templateCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("type", 'budgetPlan'),
                ], 100)
                promiseGetDocs.then(function (documentList) {
                    for (let doc of documentList.documents) {
                        budgetPlans.push({
                            id: doc.$id,
                            name: doc.name,
                            description: doc.description,
                        })
                    }
                    commit('setBudgetPlanTemplates', budgetPlans)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                })
            })
        },
        createBudgetPlan({commit}, payload) {
            return new Promise((resolve, reject) => {
                let newDoc = {
                    plannerId: payload.uid,
                    weddingId: payload.weddingId,
                    spent: 0,
                    planned: 0,
                    name: payload.name
                }
                if (payload.templateId) {
                    const promiseCreateDoc = databases.createDocument(budgetPlanCollection, "unique()", newDoc)
                    promiseCreateDoc.then(function (doc) {
                        const promiseGetDocs = databases.listDocuments(templateBudgetPlanCategoryCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("budgetPlanId", payload.templateId),
                        ],100)
                        promiseGetDocs.then(function (documentList) {
                            for (let doc2 of documentList.documents) {
                                let newCat = {
                                    name: doc2.name,
                                    budgetPlanId: doc.$id,
                                    icon: doc2.icon,
                                    plannerId: payload.uid
                                }
                                const promiseCreateDoc = databases.createDocument(budgetPlanCategoryCollection, "unique()", newCat)
                                promiseCreateDoc.then((newC) => {
                                    const promiseGetDocs = databases.listDocuments(templateBudgetPlanEntryCollection, [
                                        Query.equal("plannerId", payload.uid),
                                        Query.equal("categoryId", doc2.$id),
                                    ],100)
                                    promiseGetDocs.then(function (documentList) {
                                        for (let doc3 of documentList.documents) {
                                            let newEntry = {
                                                name: doc3.name,
                                                categoryId: newC.$id,
                                                budgetPlanId: doc.$id,
                                                plannerId: payload.uid,
                                                planned: doc3.planned,
                                                spent: doc3.spent
                                            }
                                            const promiseCreateDoc = databases.createDocument(singleBudgetCollection, "unique()", newEntry)
                                            promiseCreateDoc.then(function () {
                                            }, function (error) {
                                                console.log(error)
                                                reject(getError("Fehler beim Anlegen der Kategorien zum neuen Budgetplan.", ""))
                                            })
                                        }
                                    }, function (error) {
                                        console.log(error)
                                        reject(getError(error, ""))
                                    })
                                }, function (error) {
                                    console.log(error)
                                    reject(getError("Fehler beim Anlegen der Kategorien zum neuen Budgetplan.", ""))
                                })
                            }
                            commit('setIsInCreation', true)
                            setTimeout(() => {
                                commit('setIsInCreation', false)
                                resolve()
                            }, 800)
                        }, function (error) {
                            console.log(error)
                            reject(getError(error, ""))
                        })
                    }, function (error) {
                        console.log(error)
                        reject(getError(error, ""))
                    })
                } else {
                    const promiseCreateDoc = databases.createDocument(budgetPlanCollection, "unique()", newDoc)
                    promiseCreateDoc.then(function (doc) {
                        resolve(doc)
                    })
                }


            })
        },

        /**
         * Change budget of the current user
         *
         * @param state
         * @param payload
         */
        changeBudget({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseUpdateDoc = databases.updateDocument(budgetPlanCollection, payload.budgetPlanId, {
                    planned: Number(payload.planned)
                });
                promiseUpdateDoc.then(function (response) {
                    commit('setBudget', response.planned)
                    console.log(response); // Success
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0120"))
                });
            })
        }
        ,

        /**
         * Change budget of a single under category
         *
         * @param payload
         */
        // eslint-disable-next-line no-empty-pattern
        changeSingleBudget({}, payload) {
            return new Promise((resolve, reject) => {

                let changeSingleBudget = {
                    [`items.${payload.item.itemName}.planned`]: Number(payload.item.planned),
                    [`items.${payload.item.itemName}.spent`]: Number(payload.item.spent)
                }
                const promiseUpdateDoc = databases.updateDocument(
                    budgetPlanCollection,
                    payload.singleBudgetId,
                    changeSingleBudget
                );
                promiseUpdateDoc.then(function (response) {
                    console.log(response); // Success
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0120"))
                });
            })
        },
        getBudgetPlansForWedding({commit}, payload) {
            return new Promise((resolve, reject) => {
                let budgetPlans = []
                const promiseGetDocs = databases.listDocuments(budgetPlanCollection, [Query.equal("weddingId",
                    payload.weddingId
                )],100)
                promiseGetDocs.then(function (documentList) {
                    for (let doc of documentList.documents) {
                        let name = 'Budgetplan'
                        if (doc.name) {
                            name = doc.name
                        }
                        budgetPlans.push({
                            id: doc.$id,
                            name,
                            planned: doc.planned,
                            spent: doc.planned,
                        })

                    }
                    commit('setBudgetPlans', budgetPlans)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0121"))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteBudgetCategory({}, payload) {
            return new Promise((resolve, reject) => {
                let singleBudgetSave = []
                console.log(payload.categoryId)
                const promiseGetSingleBudget = databases.listDocuments(singleBudgetCollection, [Query.equal("categoryId",
                    payload.categoryId
                )],100)
                promiseGetSingleBudget.then((singleBudgetList) => {
                    for (let singleBudget of singleBudgetList.documents) {
                        if (singleBudget.budgetPlanId === payload.budgetPlanId) {
                            singleBudgetSave.push(singleBudget)
                        }
                    }
                    if (singleBudgetSave.length === 0) {
                        databases.deleteDocument(budgetPlanCategoryCollection, payload.categoryId).then((doc) => {
                                resolve(doc)
                            }
                        )
                    } else {
                        reject("Die Kategorie darf keine Budgets besitzen")
                    }
                })

            })
        },
        getFullPlan({commit}, payload) {
            let categories = []
            let budgetList = []
            let sumPlanned = 0
            let sumSpent = 0
            return new Promise((resolve) => {
                const promiseGetPlan = databases.getDocument(budgetPlanCollection, payload.budgetPlanId)
                promiseGetPlan.then((doc1) => {
                    const promiseGetCategories = databases.listDocuments(budgetPlanCategoryCollection, [Query.equal("budgetPlanId",
                        payload.budgetPlanId
                    )],100)
                    promiseGetCategories.then(async (docs) => {
                        for (let doc of docs.documents) {
                            categories.push(doc.name)
                            const promiseGetSingleBudget = databases.listDocuments(singleBudgetCollection, [Query.equal("categoryId",
                                doc.$id
                            ), Query.equal("budgetPlanId", payload.budgetPlanId)],100)
                            await promiseGetSingleBudget.then((singleBudgetList) => {
                                const categoryBudget = {
                                    budgetPlanId: payload.budgetPlanId,
                                    icon: doc.icon,
                                    categoryId: doc.$id,
                                    name: doc.name,
                                    singleBudget: singleBudgetList.documents,
                                    sumPlanned: 0,
                                    sumSpent: 0
                                }
                                for(let budget of categoryBudget.singleBudget){
                                    categoryBudget.sumPlanned = categoryBudget.sumPlanned + budget.planned
                                    categoryBudget.sumSpent = categoryBudget.sumSpent + budget.spent
                                    sumSpent = sumSpent +  budget.spent
                                    sumPlanned = sumPlanned + budget.planned
                                }
                                budgetList.push(categoryBudget)
                                console.log(budgetList)
                            })
                        }
                    }).then(() => {
                        commit('setCategoriesBudget', categories)
                        commit('setBudgetPlan', {name: doc1.name, sumPlanned, sumSpent, budgetList, weddingId: doc1.weddingId, id: doc1.$id})
                        resolve(budgetList)
                    })
                })

            })
        }
    }
    ,

    mutations: {
        /**
         * Set the budget to the store
         *
         * @param state
         * @param payload
         */
        setBudget(state, payload) {
            state.budget = payload
        }
        ,

        /**
         *  Set the budgetplan to the store
         *
         * @param state
         * @param payload
         */
        setBudgetPlan(state, payload) {
            state.budgetPlan = payload
        }
        ,
        setBudgetPlans(state, payload) {
            state.budgetPlans = payload
        }
        ,
        /**
         *  Set categories budget to the store
         *
         * @param state
         * @param payload
         */
        setCategoriesBudget(state, payload) {
            state.categoriesBudget = payload
        },
        setBudgetPlanTemplates(state, payload) {
            state.budgetPlanTemplates = payload
        },
        setIsInCreation(state, payload) {
            state.isInCreation = payload
        },
    }
}
