import {databases} from "../../config/server-api";
import {
    notificationCollection
} from "@/config/awSettings"
import {Query} from "appwrite";
import {getError} from "@/config/errors";

export default {
    namespaced: true,
    state: {
        notifications: [],
        notificationsUnRead: [],
        notification: {},
        countUnread: 0
    },
    getters: {
        notifications: (state) => state.notifications,
        notificationsUnRead: (state) => state.notificationsUnRead,
        notification: (state) => state.notification,
        countUnread: (state) => state.countUnread,
    },
    actions: {
        createNotification({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let newDoc = {
                    plannerId: payload.uid,
                    title: payload.notification.title,
                    icon: payload.notification.icon,
                    text: payload.notification.text,
                    read: false,
                }
                const promiseCreateDoc = databases.createDocument(notificationCollection, "unique()", newDoc)
                promiseCreateDoc.then(function () {
                    dispatch('getNotifications', {uid: payload.uid, onlyRead: true})
                    dispatch('getNotifications', {uid: payload.uid, onlyRead: false})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject('Fehler beim Erstellen der Benachrichtigung.')
                })
            })
        },

        /**
         * Holt und setzt alle Customer eines bestimmten PLaners
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getNotifications({commit}, payload) {
            return new Promise((resolve, reject) => {
                let promiseGetDocs
                if (payload.onlyUnread) {
                    promiseGetDocs = databases.listDocuments(notificationCollection, [Query.equal("plannerId",
                        payload.uid),
                        Query.equal("read",
                            false),
                    ],100)
                }else {
                    promiseGetDocs = databases.listDocuments(notificationCollection, [Query.equal("plannerId",
                        payload.uid)],100)
                }
                promiseGetDocs.then(function (documentList) {
                    let notifications = []
                    let index = 1
                    let unReadCount = 0
                    for (let doc of documentList.documents) {
                        let notification = {
                            id: doc.$id,
                            title: doc.title,
                            icon: doc.icon,
                            text: doc.text,
                            read: doc.read,
                            created: doc.$createdAt*1000
                        }
                        if(payload.limit){
                            if(index<=payload.limit){
                                notifications.push(notification)
                            }
                        }else{
                            notifications.push(notification)
                        }
                        if(!notification.read){
                            unReadCount++
                        }
                        index++
                    }
                    notifications.sort(function(a, b) {
                        return b.created - a.created;
                    })
                    if (payload.onlyUnread) {
                        commit('setNotificationsUnRead', notifications)
                    }else{
                        commit('setNotifications', notifications)
                    }
                    commit('setCountUnread', unReadCount)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject('Fehler beim lesen der Benachrichtigungen.')
                });
            })
        },
        getNotification({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(notificationCollection, payload.notificationId)
                promiseGetDoc.then(function (doc) {
                    let notification = {
                        id: doc.$id,
                        title: doc.title,
                        icon: doc.icon,
                        text: doc.text,
                        read: doc.read,
                    }
                    commit('setNotification', notification)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0024"))
                });
            })
        },
    },
    mutations: {
        setNotifications(state, payload) {
            state.notifications = payload
        },
        setNotification(state, payload) {
            state.notification = payload
        },
        setNotificationsUnRead(state, payload) {
            state.notificationsUnRead = payload
        },
        setCountUnread(state, payload) {
            state.countUnread = payload
        },
    }
}
