import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css' // import the croppie css manually
import "bootstrap-vue/dist/bootstrap-vue.css"; // required for css for v-icon-picker
import '@mdi/font/css/materialdesignicons.css' //required for get list mdi Icon
import VueShepherd from 'vue-shepherd';
import 'leaflet/dist/leaflet.css';


Vue.use(VueShepherd);
Vue.config.productionTip = false
Vue.use(VueCroppie);

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
