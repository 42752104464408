import {databases} from "@/config/server-api";
import {Query} from "appwrite";
import router from "@/router";
import {getError} from "@/config/errors";
const delay = ms => new Promise(res => setTimeout(res, ms));

import {
    budgetPlanCollection,
    contactPersonCollection,
    customerCollection, customFieldValueCollection,
    eventCollection, invoiceCollection,
    leadCollection, locationCollection, notificationCollection, plannerAccountCollection,
    taskCollection,
    vendorCollection,
    weddingCollection,
    weddingVendorsCollection,
} from "@/config/awSettings"
export default {
    namespaced: true,
    state: {
        /**
         * Contains general customers Information
         */
        customers: [],
        /**
         * Contains general customer Information
         */
        customer: {},
        /**
         * Kunden für die Selektion bei Dropdowns
         */
        customersArchived: [],
        customersForSelection: [],
        customerCreatedId: '',
        customersSearched: [],
        customersSearchedArchived: []
    },
    getters: {
        customers: (state) => state.customers,
        customersArchived: (state) => state.customersArchived,
        customersForSelection: (state) => state.customersForSelection,
        customer: (state) => state.customer,
        customerCreatedId: (state) => state.customerCreatedId,
        customersSearched: (state) => state.customersSearched,
        customersSearchedArchived: (state) => state.customersSearchedArchived,
    },
    actions: {
        /**
         * Erstellt einen neuen Kunden
         *
         * @param dispatch
         * @param commit
         * @param payload
         */
        // eslint-disable-next-line no-empty-pattern
        createCustomer({commit}, payload) {
            return new Promise((resolve, reject) => {
                let newDoc = {
                    plannerId: payload.uid,
                    firstname: payload.newCustomer.firstname,
                    lastname: payload.newCustomer.lastname,
                    partnerFirstname: payload.newCustomer.partnerFirstname,
                    partnerLastname: payload.newCustomer.partnerLastname,
                    tel: payload.newCustomer.tel,
                }
                if (payload.newCustomer.email) {
                    newDoc = {
                        ...newDoc,
                        email: payload.newCustomer.email
                    }
                }
                let importId
                if (payload.newCustomer.$id) {
                    importId = payload.newCustomer.$id
                } else if (payload.newCustomer.id) {
                    importId = payload.newCustomer.id

                } else {
                    importId = 'unique()'
                }
                delete payload.newCustomer['id'];
                delete payload.newCustomer['$id'];
                const promiseCreateDoc = databases.createDocument(customerCollection, importId, newDoc)
                promiseCreateDoc.then(function (doc) {
                    commit('setCustomerCreatedId', doc.$id)
                    if (payload.openAfterCreate) {
                        router.push('/kunden/kunde/' + doc.$id).then(() => {
                            resolve(doc.$id)
                        })
                    } else {
                        resolve(doc.$id)
                    }
                }, function (error) {
                    console.log(error)
                    reject(getError(error, "E0020"))
                })
            })
        },
        /**
         * Löscht einen Kunden
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        deleteCustomer({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(customerCollection, payload.customerId);
                promiseDeleteDoc.then(function () {
                    dispatch('getCustomers', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0021"))
                });
            })
        },
        /**
         * Holt und setzt alle Customer eines bestimmten PLaners
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getCustomers({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(customerCollection, [Query.equal("plannerId",
                    payload.uid
                )], 0)
                promiseGetDocs.then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let customers = []
                    let customersArchive = []
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(customerCollection, [Query.equal("plannerId",
                            payload.uid
                        )], 25, i * 25).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let customer = {
                                    values: {
                                        firstname: doc.firstname,
                                        lastname: doc.lastname,
                                        status: doc.status,
                                        email: doc.email,
                                    },
                                    export: doc,
                                    partnerFirstname: doc.partnerFirstname,
                                    partnerLastname: doc.partnerLastname,
                                    id: doc.$id,
                                }
                                delete customer.export['$collection'];
                                delete customer.export['$read'];
                                delete customer.export['$write'];

                                if (doc.isArchived) {
                                    customersArchive.push(customer)
                                } else {
                                    customers.push(customer)
                                }
                            }
                        }).catch((err) => {
                            console.log(err); // Failure
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('setCustomers', customers)
                                commit('setSearchedCustomers', customers)
                                commit('setCustomersArchive', customersArchive)
                                commit('setSearchedCustomersArchive', customersArchive)
                                resolve()
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0022"))
                });
            })
        },
        /**
         * Holt alle Customer zu einer plannerId und setzt sie als Auswahl für customer Dropdowns
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getCustomersForSelection({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(customerCollection, [Query.equal("plannerId",
                    payload.uid
                )],0)
                promiseGetDocs.then(async function (documentListX) {
                    let pages = Math.ceil(documentListX.total / 25)
                    let customers = []
                    for(let i = 0; i <pages; i++){
                        databases.listDocuments(customerCollection, [Query.equal("plannerId",
                            payload.uid
                        )],25, i*25).then((documentList)=>{
                            for (let doc of documentList.documents) {
                                let text
                                if (doc.firstname && doc.lastname) {
                                    text = doc.firstname + ' ' + doc.lastname + ' | ' + doc.partnerFirstname
                                } else if (doc.firstname) {
                                    text = doc.firstname
                                } else {
                                    text = doc.lastname
                                }
                                if (doc.status) {
                                    text = text + ' | ' + doc.status
                                }
                                let customer = {
                                    text: text,
                                    value: doc.$id
                                }
                                if (!doc.isArchived) {
                                    customers.push(customer)
                                }
                            }
                        }).catch((err)=>{
                            console.log(err)
                        }).finally(()=>{
                            commit('setCustomersForSelection', customers)
                            resolve()
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0023"))
                });
            })
        },
        /**
         * liest einen speziellen Customer aus der Datenbank
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getCustomer({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(customerCollection, payload.customerId)
                promiseGetDoc.then(function (doc) {
                    let customer = {
                        firstname: doc.firstname,
                        lastname: doc.lastname,
                        partnerFirstname: doc.partnerFirstname,
                        partnerLastname: doc.partnerLastname,
                        partnerTel: doc.partnerTel,
                        partnerMail: doc.partnerMail,
                        street: doc.street,
                        houseNumber: doc.houseNumber,
                        plz: doc.plz,
                        city: doc.city,
                        email: doc.email,
                        status: doc.status,
                        notes: doc.notes,
                        tel: doc.tel,
                        id: doc.$id,
                        created: doc.$createdAt * 1000,
                        isArchived: doc.isArchived
                    }
                    commit('setCustomer', customer)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0024"))
                });
            })
        },
        /**
         * Editiert ein Feld eines beliebigen Datensatzes
         *
         * @param payload
         * @returns {Promise<unknown>}
         */
        // eslint-disable-next-line no-empty-pattern
        editField({}, payload) {
            let editValue = payload.newValue
            return new Promise((resolve, reject) => {
                let entity;
                console.log(payload)
                if (payload.row === 'wedDate' || payload.row === 'date' || payload.row === 'start' || payload.row === 'end') {
                    if (!payload.newValue) {
                        reject(getError("Bitte gültigen Wert eingeben.", "E0100"))
                        return
                    } else {
                        editValue = new Date(editValue).getTime()
                    }
                }
                switch (payload.entity) {
                    case "vendors":
                        entity = vendorCollection
                        break;
                    case "contactPerson":
                        entity = contactPersonCollection
                        break;
                    case "wedding":
                        entity = weddingCollection
                        break;
                    case "events":
                        entity = eventCollection
                        break;
                    case "weddingVendors":
                        entity = weddingVendorsCollection
                        break;
                    case "lead":
                        entity = leadCollection
                        break;
                    case "tasks":
                        entity = taskCollection
                        break;
                    case "customers":
                        entity = customerCollection
                        break;
                    case "planner":
                        entity = plannerAccountCollection
                        break;
                    case "budgetPlan":
                        entity = budgetPlanCollection
                        break;
                    case 'customFieldValue':
                        entity = customFieldValueCollection
                        break;
                    case 'notifications':
                        entity = notificationCollection
                        break;
                    case 'locations':
                        entity = locationCollection
                        break
                    case 'invoice':
                        entity = invoiceCollection
                        break;
                }
                if (editValue === '') {
                    editValue = null
                }
                const promiseUpdateDoc = databases.updateDocument(entity, payload.id, {
                    [payload.row]: editValue
                });
                promiseUpdateDoc.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0100"))
                });
            })
        },
        setCustomersSearched({commit}, payload) {
            return new Promise((resolve) => {
                commit('setSearchedCustomers', payload)
                resolve()
            })
        },
        setCustomersSearchedArchived({commit}, payload) {
            return new Promise((resolve) => {
                commit('setSearchedCustomersArchive', payload)
                resolve()
            })
        }

    },
    mutations: {
        setCustomers(state, payload) {
            state.customers = payload
        },
        setCustomersArchive(state, payload) {
            state.customersArchived = payload
        },
        setSearchedCustomers(state, payload) {
            state.customersSearched = payload
        },
        setSearchedCustomersArchive(state, payload) {
            state.customersSearchedArchived = payload
        },
        addCustomer(state, payload) {
            state.customers.push(payload)
        },
        setCustomer(state, payload) {
            state.customer = payload
        },
        setCustomerCreatedId(state, payload) {
            state.customerCreatedId = payload
        },
        setCustomersForSelection(state, payload) {
            state.customersForSelection = payload
        },
    }
}
