import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    /**
     * General Section
     */
    {
        path: '/',
        name: 'Dashboard',
        component: () => import('@/views/Planner/Dashboard'),
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
    },
    {
        path: '/planner/login',
        name: 'PlanerLogin',
        component: () => import('@/views/auth/Login'),
    },
    {
        path: '/support',
        name: 'Support',
        component: () => import('@/views/general/Support'),
    },
    {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('@/views/general/Feedback'),
    },
    {
        path: '/excel',
        name: 'Excel',
        component: () => import('@/components/imAndExport/ExcelExport'),
    },
    {
        path: '/resetPasswort',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPassword'),
    },
    {
        path: '/testphase-abgelaufen',
        name: 'TestEnded',
        component: () => import('@/views/auth/TestEnded'),
    },
    {
        path: '/activateMyAccount!wedFlow2022!',
        name: 'CreatePaymentStatus',
        component: () => import('@/views/auth/CreatePaymentStatus'),
    },
    {
        path: '/loginForActivateMyAccount!wedFlow2022!',
        name: 'LoginForCreatePaymentStatus',
        component: () => import('@/views/auth/LoginForCreatePaymentStatus'),
    },
    {
        path: '/createdPayment',
        name: 'CreatedPayment',
        component: () => import('@/views/auth/CreatedPayment'),
    },
    {
        path: '/adminPanel',
        name: 'Admin',
        component: () => import('@/views/back/Admin'),
    },
    {
        path: '/verifyMail',
        name: 'VerifyEmail',
        component: () => import('@/views/auth/VerifyEmail'),
    },
    {
        path: '/fast-geschafft',
        name: 'PleaseVerifyMail',
        component: () => import('@/views/auth/PleaseVerifyMail'),
    },
    {
        path: '/registrieren',
        name: 'Register',
        component: () => import('@/views/auth/Register'),
    },
    {
        path: '/planner/dashboard',
        name: 'Dashboard2',
        component: () => import('@/views/Planner/Dashboard'),
    },
    {
        path: '/impressum',
        name: 'Imprint',
        component: () => import('@/views/general/Imprint'),
    },
    {
        path: '/email',
        name: 'Inbox',
        component: () => import('@/views/entities/Email/Inbox'),
    },
    {
        path: '/datenschutzerklaerung',
        name: 'DataProtection',
        component: () => import('@/views/general/DataProtection.vue'),
    },
    {
        path: '/agb',
        name: 'AGB',
        component: () => import('@/views/general/AGB.vue'),
    },
    {
        path: '/avv/:uid',
        name: 'AVV',
        component: () => import('@/views/general/AVV.vue'),
    },
    {
        path: '/pdf/invoice/:id',
        name: 'pdfInvoice',
        component: () => import('@/views/Pdf'),
    },
    {
        path: '/pdf/budgetPlan/:id',
        name: 'pdfBudgetPlan',
        component: () => import('@/views/Pdf'),
    },
    {
        path: '/forgotPassword',
        name: 'Passwort vergessen',
        component: () => import('@/views/auth/ForgotPassword'),
    },
    {
        path: '/verifyEmail',
        name: 'Verifiziere Email',
        component: () => import('@/views/auth/VerifyEmail'),
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/general/About.vue')
    },
    {
        path: '/Settings',
        name: 'Setting',
        component: () => import('@/views/Planner/Settings'),
    },
    {
        path: '/createAccount',
        name: 'CreateAccount',
        component: () => import('@/views/auth/CreateAccount'),
    },
    /**
     * entities
     */
    {
        path: '/kunden',
        name: 'Customers',
        component: () => import('../views/entities/Customer/Customers'),
    },
    {
        path: '/rechnungen',
        name: 'Rechnungen',
        component: () => import('../views/entities/Invoice/Invoice'),
    },
    {
        path: '/rechnungen/rechnung/:id',
        name: 'RechnungenDetail',
        component: () => import('../views/entities/Invoice/InvoiceDetail'),
    },
    {
        path: '/kunden/kunde/:id',
        name: 'CustomerDetail',
        component: () => import('@/views/entities/Customer/CustomerDetail'),
    },
    {
        path: '/locations',
        name: 'Locations',
        component: () => import('../views/entities/Location/Locations'),
    },

    {
        path: '/locations/location/:id',
        name: 'LocationDetail',
        component: () => import('@/views/entities/Location/LocationDetail'),
    },
    {
        path: '/planner/todo',
        name: 'Tasks',
        component: () => import('@/views/entities/Task/Tasks'),
    },
    {
        path: '/planner/tasks/task/:id',
        name: 'TasksDetail',
        component: () => import('@/views/entities/Task/TaskDetail'),
    },
    {
        path: '/dienstleister',
        name: 'Vendors',
        component: () => import('@/views/entities/Vendor/Vendors'),
    },
    {
        path: '/dienstleister/dienstleister/:id',
        name: 'VendorDetail',
        component: () => import('@/views/entities/Vendor/VendorDetail'),
    },
    {
        path: '/weddings',
        name: 'Weddings',
        component: () => import('@/views/entities/Wedding/Weddings'),
    },
    {
        path: '/weddings/wedding/:id',
        name: 'WeddingDetail',
        component: () => import('@/views/entities/Wedding/WeddingDetail'),
    },
    {
        path: '/weddings/wedding/:weddingId/budgetPlan/:id',
        name: 'BudgetPlan',
        component: () => import('@/components/entities/wedding/BudgetPlan/BudgetPlanner'),
    },
    {
        path: '/weddings/wedding/:weddingId/budgetPlan/:id/pdf',
        name: 'BudgetPlanPDF',
        component: () => import('@/components/entities/wedding/BudgetPlan/BudgetPlanner'),
    },
    {
        path: '/weddings/wedding/:weddingId/timePlan/:id',
        name: 'TimePlan',
        component: () => import('@/components/entities/wedding/TimePlan/TimePlan'),
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('@/views/entities/Calendar/Calendar'),
    },
    {
        path: '/calendar/event/:id',
        name: 'Event',
        component: () => import('@/views/entities/Calendar/EventDetail'),
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: () => import('@/views/Planner/Notifications'),
    },
    {
        path: '/notifications/notification/:id',
        name: 'Notification',
        component: () => import('@/views/Planner/Notification'),
    },
]

const router = new VueRouter({
    routes,
})

export default router
