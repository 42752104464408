import {databases, functions} from "@/config/server-api";
import {
    paymenStatusCollection,
} from "@/config/awSettings"

export default {
    namespaced: true,
    state: {
        portalUrl: null
    },
    getters: {
        portalUrl: (state) => state.portalUrl,
    },
    actions: {
        // eslint-disable-next-line no-empty-pattern
        getPlanForUser({}, uid) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(paymenStatusCollection, uid)
                promiseGetDoc.then(function (paymentDoc) {
                    if (paymentDoc.active) {
                        resolve(paymentDoc.plan)
                    } else {
                        reject('Dein Zahlungsplan ist inaktiv.')
                    }
                }).catch(() => {
                    // (noch) kein Paymentdoc vorhanden -> Testphase
                    resolve(0)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        createPaymentStatus({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = databases.createDocument(paymenStatusCollection, payload.uid, {
                    status: 'unverified',
                    plan: payload.plan,
                    plannerId: payload.uid,
                    active: true,
                    stripeId: ''
                })
                promiseCreateDoc.then(() => {
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        getPortalForUser({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = functions.createExecution('638ba15acb0e6561eaa5', payload.stripeId)
                promiseCreateDoc.then((excecution) => {
                    commit('setPortalUrl', JSON.parse(excecution.response).url)
                    resolve()
                }).catch((err) => {
                    reject(err)
                })
            })
        },
    },
    mutations: {
        setPortalUrl(state, payload) {
            state.portalUrl = payload
        },
    }
}
