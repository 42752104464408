import {databases} from "../../config/server-api";
import {Query} from "appwrite";
import {getError} from "@/config/errors";
import {
    taskCollection,
    weddingCollection,
    customerCollection
} from "@/config/awSettings"
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
    namespaced: true,
    state: {
        tasks: [],
        doneTasks: [],
        undoneTasks: [],
        task: {},
        tasksForWedding: []
    },
    getters: {
        tasks: (state) => state.tasks,
        doneTasks: (state) => state.doneTasks,
        undoneTasks: (state) => state.undoneTasks,
        task: (state) => state.task,
        tasksForWedding: (state) => state.tasksForWedding,
    },
    actions: {
        /**
         * Erstellt eine neue Aufgabe
         *
         * @param dispatch
         * @param payload
         */
        createTask({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                if (payload.newTask.subject) {
                    const promiseCreateDoc = databases.createDocument(taskCollection, "unique()", {
                        plannerId: payload.uid,
                        subject: payload.newTask.subject,
                        prio: payload.newTask.prio,
                        date: payload.newTask.date,
                        description: payload.newTask.description,
                        weddingId: payload.newTask.weddingId,
                        status: payload.newTask.status,
                    })

                    promiseCreateDoc.then(function () {
                        dispatch('getTasks', {uid: payload.uid})
                        if (payload.newTask.weddingId) {
                            dispatch('getTasksForWedding', {uid: payload.uid, weddingId: payload.newTask.weddingId})
                        }
                        resolve()
                    }, function (error) {
                        console.log(error)
                        reject(getError(error, "E0050"))
                    })
                } else {
                    reject(getError("Bitte lege zuerst einen Betreff fest.", "E0051"))
                }

            })
        },
        /**
         * Liest alle Tasks eines Planers
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getTasks({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(taskCollection, [
                    Query.equal("plannerId", payload.uid),
                ],0)
                promiseGetDocs.then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let tasks = []
                    for(let i = 0; i <pages; i++){
                        databases.listDocuments(taskCollection, [
                            Query.equal("plannerId", payload.uid),
                        ],25,25*i) .then((documentList) => {
                            for (let doc of documentList.documents) {
                                let newDate = null
                                if(doc.date){
                                    newDate = new Date(doc.date)
                                }
                                let task = {
                                    subject: doc.subject,
                                    status: doc.status,
                                    prio: doc.prio,
                                    date: newDate,
                                    description: doc.description,
                                    weddingId: doc.weddingId,
                                    id: doc.$id,
                                    export: doc
                                }
                                delete task.export['$collection'];
                                delete task.export['$read'];
                                delete task.export['$write'];
                                tasks.push(task)
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('setTasks', tasks)
                                resolve()
                            }
                        })
                        await delay(100)
                    }
                }).catch((err) => {
                    console.log(err); // Failure
                    reject(getError(err, "E0052"))
                })
            })
        },
        /**
         * Liest alle Tasks einer Hochzeit eines Planers
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getTasksForWedding({commit}, payload) {
            commit('setTasksForWedding', [])
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(taskCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("weddingId", payload.weddingId),
                ],0)
                promiseGetDocs.then(async function (documentListX) {
                    if(documentListX.total === 0){
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let tasks = []
                    for(let i = 0; i <pages; i++){
                        databases.listDocuments(taskCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("weddingId", payload.weddingId),
                        ],25,25*i).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let properDate = null
                                if(doc.date){
                                    properDate = new Date(doc.date )
                                }
                                let task = {
                                    subject: doc.subject,
                                    status: doc.status,
                                    prio: doc.prio,
                                    date: properDate,
                                    description: doc.description,
                                    weddingId: doc.weddingId,
                                    id: doc.$id,
                                }
                                tasks.push(task)
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                commit('setTasksForWedding', tasks)
                                resolve()
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0053"))
                });
            })
        }
        ,
        /**
         * Liest Detailinformationen zu einem Task
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getTask({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(taskCollection, payload.taskId)
                promiseGetDoc.then(function (doc) {
                    if (doc.weddingId) {
                        const promiseGetDoc = databases.getDocument(weddingCollection, doc.weddingId)
                        promiseGetDoc.then(function (doc2) {
                            if (doc2.customerId) {
                                const promiseGetDoc = databases.getDocument(customerCollection, doc2.customerId)
                                promiseGetDoc.then(function (doc3) {
                                    let weddingName = getCustomerName(doc3.firstname, doc3.partnerFirstname)
                                    let newDate = null
                                    if(doc.date){
                                        newDate = new Date(doc.date )
                                    }
                                    let task = {
                                        weddingName,
                                        subject: doc.subject,
                                        prio: doc.prio,
                                        date: newDate,
                                        description: doc.description,
                                        weddingId: doc.weddingId,
                                        status: doc.status,
                                        id: doc.$id,
                                        created: doc.$createdAt* 1000
                                    }
                                    commit('setTask', task)
                                    resolve()
                                }).catch((err) => {
                                    console.log(err); // Failure
                                    reject(getError(err, "E0054"))
                                })
                            }
                        }).catch(() => {
                            let task = {
                                subject: doc.subject,
                                prio: doc.prio,
                                date: doc.date,
                                description: doc.description,
                                weddingId: doc.weddingId,
                                status: doc.status,
                                id: doc.$id,
                                created: doc.$createdAt* 1000,
                                weddingName: 'Hochzeit wurde gelöscht',
                            }
                            commit('setTask', task)
                            resolve()
                        })
                    }else{
                        let task = {
                            subject: doc.subject,
                            prio: doc.prio,
                            date: doc.date,
                            description: doc.description,
                            weddingId: doc.weddingId,
                            status: doc.status,
                            id: doc.$id,
                            created: doc.$createdAt* 1000
                        }
                        commit('setTask', task)
                        resolve()
                    }
                }).catch((err) => {
                    console.log(err); // Failure
                    reject(getError(err, "E0056"))
                })
            })
        }
        ,
        /**
         * Markiert eine Aufgabe als erledigt
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        setTaskDone({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseUpdateDoc = databases.updateDocument(taskCollection, payload.taskId, {
                    status: 'Erledigt',
                });
                promiseUpdateDoc.then(function () {
                    dispatch('setTasksEmpty').then(() => {
                        dispatch('getTasks', {uid: payload.uid})
                    })
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0057"))
                });
            })
        }
        ,
        /**
         * Markiert eine Aufgabe als Zu erledigen
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        setTaskUndone({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseUpdateDoc = databases.updateDocument(taskCollection, payload.taskId, {
                    status: 'Zu erledigt',
                });
                promiseUpdateDoc.then(function () {
                    dispatch('setTasksEmpty').then(() => {
                        dispatch('getTasks', {uid: payload.uid})
                    })
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0058"))
                });
            })
        }
        ,
        /**
         * Löscht einen Task
         *
         * @param dispatch
         * @param payload
         */
        deleteTasks({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(taskCollection, payload.taskId);
                promiseDeleteDoc.then(function () {
                    dispatch('setTasksEmpty').then(() => {
                        dispatch('getTasks', {uid: payload.uid})
                    })
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0059"))
                });
            })
        }
        ,
        setTasksEmpty({commit}) {
            let emptyList = []
            commit('setTasks', emptyList)
        }
        ,
    },
    mutations: {
        setTasks(state, payload) {
            let doneTasks = []
            let undoneTasks = []
            for (let task of payload) {
                if (task.status === 'Erledigt') {
                    doneTasks.push(task)
                } else {
                    undoneTasks.push(task)
                }
            }
            state.tasks = payload
            state.doneTasks = doneTasks
            state.undoneTasks = undoneTasks
        }
        ,
        setTask(state, payload) {
            state.task = payload
        }
        ,
        setTasksForWedding(state, payload) {
            state.tasksForWedding = payload
        }
        ,
    }
}

function getCustomerName(firstname, partnerFirstname) {
    if (firstname !== '' && partnerFirstname === '') {
        return firstname
    } else if (firstname === '' && partnerFirstname !== '') {
        return partnerFirstname
    } else if (firstname !== '' && partnerFirstname !== '') {
        return firstname + " und " + partnerFirstname
    } else {
        return ''
    }
}
