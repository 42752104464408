import {databases} from "@/config/server-api";
import {getError} from "@/config/errors";
import {
    notificationCollection,
    plannerAccountCollection,
} from "@/config/awSettings"
export default {
    namespaced: true,
    state: {
        users: []
    },
    getters: {
        users: (state) => state.users,
    },
    actions: {
        /**
         * Erstellt einen neuen Lead
         *
         * @param payload
         * @returns {Promise<unknown>}
         */
        getUsers({commit}) {
            return new Promise((resolve, reject) => {
                databases.listDocuments(plannerAccountCollection).then((res)=>{
                    commit('addUsers', res.documents)
                }).catch((error)=> {
                    console.log(error)
                    reject(getError(error,"E0101"))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        createMessageForAll({}, payload) {
            return new Promise(() => {
                for(let user of payload.users){
                    databases.createDocument(notificationCollection, 'unique()', {
                        read: false,
                        icon: payload.icon,
                        text: payload.message,
                        title: payload.title,
                        plannerId: user.$id,
                    },[
                        'user:'+payload.uid,
                    ], [
                        'user:'+payload.uid,
                    ]).then(()=>{
                        console.log(user.$id +": Nachricht erstellt")
                    }).catch((error)=> {
                        console.log(user.$id +": Fehler: "+ error)
                    }).finally(()=>{
                        console.log("-------------------------------------")
                    })
                }
            })
        },
    },
    mutations: {
        addUsers(state, payload) {
            state.users = payload
        },
    }
}
