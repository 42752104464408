import {Account, Client, Databases, Storage, Functions, Teams} from "appwrite";
import {projectId, databaseId, endpoint} from "@/config/awSettings";
const api = new Client();

export const account = new Account(api);
export const databases = new Databases(api, databaseId);
export const storage = new Storage(api);
export const functions = new Functions(api)
export const teams = new Teams(api);

api.setEndpoint(endpoint);
api.setProject(projectId)

